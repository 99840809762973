import React, {  useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import EditTurbine from './EditTurbine';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';

EditTurbines.propTypes = {
  turbines: PropTypes.array,
  setTurbines: PropTypes.func,
  setValid: PropTypes.func
};

export default function EditTurbines({turbines, setTurbines, setValid}) {

    const [values, setValues] = useState(turbines);
    const [validation, setValidation] = useState(turbines.map((turbine) =>
    {
      return {item:turbine, valid:true};
    }
    ));
    
    const [didMount, setDidMount] = useState(false);
    
    // Setting didMount to true upon mounting
    useEffect(() => { setDidMount(true) }, [])

    useEffect(() => {
      if(didMount){
        setValues(turbines);
        turbines.map((turbine) =>
        {
          return {item:turbine, valid:true};
        }
        )
      }
    }, [turbines]);

    const setItemValid = (newItem, valid) => {
      const updatedValidation = validation.map((validationItem) => {
        if(validationItem.item.id == newItem.id) {
          return { item: newItem , valid: valid};
        }else {
          return validationItem;
        }
      });
      setValidation(updatedValidation);
    }
   
    const handleItemChange = (item) => {
      const updatedValues = values.map((value) => {
        if(item.id == value.id) {
          return item;
        }else {
          return value;
        }
      })
      setValues(updatedValues);  
    };

    useEffect(() => {
      if(didMount){
        setTurbinesIfValidated();
      }
    }, [validation]);

    const setTurbinesIfValidated = () => {
      if(validation.every( (validationItem)  => {
        return validationItem != undefined && validationItem.valid == true; 
        }))
      {
        const turbinesToSave = values.filter((i) => i.name != '');
        setTurbines(turbinesToSave);
        setValid(true);
      }else{
        setValid(false);
      }
    }
    
    const addValue = () => {
      const newItem = {id: uuidv4(), name:'', min:'', max:'', priority:0, isNew: true};
      setValues(values.concat(newItem));
      setValidation(validation.concat({item:newItem,valid:false}));
    };


    const deleteValue = (turbine) => {
      const updatedValues = values.filter((item) => item.id !== turbine.id);
      setValues(updatedValues); 
      const updateValidation = validation.filter((validationItem) => validationItem.item.id !== turbine.id);
      setValidation(updateValidation);
    };

    return (
      <React.Fragment>
      <Card>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Machines
          </Typography>
            
            {values.map((turbine) => (
              <Typography key={turbine.id} sx={{ mb: 1.5 }} variant="h5" component="div">
                <Stack direction="row">
                  <EditTurbine turbine={turbine} setTurbine={handleItemChange} setValid={setItemValid}/>
                  <IconButton aria-label="delete" onClick={() => deleteValue(turbine)}>
                    <DeleteIcon/>
                  </IconButton>
                </Stack>
              </Typography>
            
            ))}
            
            <IconButton aria-label="add" onClick={addValue}>
              <AddIcon />
            </IconButton>
        </CardContent>
      </Card>
      </React.Fragment>
    );
}
