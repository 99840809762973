import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
  
import PropTypes from 'prop-types';

ShowLineChart.propTypes = {
  titleOptions: PropTypes.object,
  data : PropTypes.object,
  value: PropTypes.number,
  index: PropTypes.number, 
}

export default function ShowLineChart({titleOptions, data, value, index}) {

  //const { data, value, index, ...other } = props;

  ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
        position: 'top',
        },
        title: titleOptions,
    },
    };

//const labels = values.labels;
// const data = {
// labels,
// datasets: [
//     {
//     label: chartOptions.label,
//     data: values.data,
//     borderColor: chartOptions.borderColor,
//     backgroundColor: chartOptions.backgroundColor,
//     },
// ],
// };

  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Line options={options} data={data} />
      </Box>
    )}
  </div>
  );
}