import React, {useState,useContext}  from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { AuthContext } from '../App';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ErrorMessage } from '../common/Constant';


SetupDelete.propTypes = {
  setup: PropTypes.object,
  setupDeleteCallback: PropTypes.func
}

export default function SetupDelete({setup, setupDeleteCallback}) {
  
    const authContext = useContext(AuthContext);
    const [open, setOpen] = useState(false);

    const [rejection, setRejection] = useState({open:false,message:""});

    const handleRejectionClose = () => {
        setRejection({open:false,message:""});
    }

    const deleteSetup = () => {
    fetch('api/setups/delete',
        {
            method: "DELETE",
            cache: "no-cache",
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authContext.token}`
            },
            body: JSON.stringify(setup)
        })
        .then(response => {
            if(response.ok) {
            response.json().then(() => {
                setupDeleteCallback();
            });
            }else if(response.status == 400){
              response.json().then(error => {
                setRejection({open:true, message: ErrorMessage(error)});
              });
            }else{
              setRejection({open:true, message:"Server error"}); 
            }
        }).catch((err) => {
            console.log(err.message);
            setRejection({open:true, message:"Internal error"});
        });
    }

        
    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
      deleteSetup();
        setOpen(false);
    };

    const handleReject = () => {
        setOpen(false);
    };
 
    const getConfirmationText = () => {
      //return "Are you shure you want to delete setup" + {setup.description} + " of type " +{setup.setupType} + "created " + {setup.created}  + "? ";
      return "Are you shure ?";
    };

  return (
    <React.Fragment>
         {/* <Snackbar
        severity="success"
        open={confirmation.open}
        autoHideDuration={3000}
        onClose={handleConfirmationClose}
        message={confirmation.message}
      /> */}
      <Snackbar
        severity="error"
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
      <IconButton aria-label="delete" onClick={() => setOpen(true)}>
        <DeleteIcon/>
      </IconButton>
      
      
          <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {getConfirmationText()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReject}>Disagree</Button>
            <Button onClick={handleConfirm} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>  
    </React.Fragment>
  );
}
