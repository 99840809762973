import React,{ FC } from 'react';
import Typography from '@mui/material/Typography';
//import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import {Item} from '../common/Constant';
import { Plant } from '../common/types';


const ShowPlantBasicCard: FC<{ plant: Plant }> = ({ plant }) => {

  return (
    <Box sx={{ mb: 2, minWidth: 275, flexGrow: 1 }}>
      
      <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          Details for plant {plant.name}
        </Typography>
        
        <Grid container spacing={2} >
        <Grid xs={4}>
          <Item>
            <Typography sx={{ mb: 1.5 }} variant="subtitle1" component="div">
            Drop loss coefficient (s2/m5)
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body1" component="div">
              {plant.dropLossCoefficient}
           </Typography>
          </Item>
        </Grid>
        <Grid xs={4}>
          <Item>
            <Typography sx={{ mb: 1.5 }} variant="subtitle1" component="div">
            Center turbine (Moh)
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body1" component="div">
            {plant.centerTurbineMoh}
           </Typography>
          </Item>
        </Grid>
        <Grid xs={4}>
          <Item>
            <Typography sx={{ mb: 1.5 }} variant="subtitle1" component="div">
            Machine steps
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body1" component="div">
            {plant.step}
            {/* {plant.step} - {StepTypePrint(plant.stepType)} */}
           </Typography>
          </Item>
        </Grid>
        <Grid xs={4}>
          <Item>
            <Typography sx={{ mb: 1.5 }} variant="subtitle1" component="div">
            Energy equivalent (kWh/m3)
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body1" component="div">
            {plant.energyEquivalent}
           </Typography>
          </Item>
        </Grid>
        <Grid xs={4}>
          <Item>
            <Typography sx={{ mb: 1.5 }} variant="subtitle1" component="div">
            Hrv Water value (NOK/MWh)
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body1" component="div">
            {plant.hrvWaterValue}
           </Typography>
          </Item>
        </Grid>
        <Grid xs={4}>
          <Item>
            <Typography sx={{ mb: 1.5 }} variant="subtitle1" component="div">
            Lrv Water value (NOK/MWh)
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body1" component="div">
            {plant.lrvWaterValue}
            {/* {plant.step} - {StepTypePrint(plant.stepType)} */}
           </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
export default ShowPlantBasicCard;