//import { v4 as uuidv4 } from 'uuid';

export enum StepType {
    Unassigned = 0,
    Flow = 1,
    Step = 2
}

export enum Frequency 
{
    Unassigned = 0,
    Quarterly = 1,
    Hourly = 2,
    Daily = 3,
    Weekly = 4,
    Monthly = 5,
    Yearly = 6
}

export enum SetupCategory {
    unassigned = 0,
    basicsetup = 1,
    calculation = 2
}

export enum SetupType 
{
    unassigned = 0,
    price = 1,
    inflow = 2,
    reservoir = 3,
    turbinepower = 4,
    generatorpower = 5,
    availability = 6
}

export enum SetupLevel {
    unassigned = 0,
    company = 1,
    plant = 2,
    turbine = 3
}

export interface ServerError{
    code: number
    message: string
} 

export const FrequencyPrint = (frequency: Frequency) => {
    if(frequency == Frequency.Quarterly)
        return "quarterly";
    if(frequency == Frequency.Hourly)
        return "hourly";
    else if(frequency == Frequency.Daily)
        return "daily";
    else if(frequency == Frequency.Weekly)
        return "weekly";
    return "Unassigned";  
  } 


// export interface SetupType2 
// {
//     unassigned : 0;
//     price : 1;
//     inflow : 2;
//     reservoir : 3;
//     turbinepower : 4;
//     generatorpower : 5;
//     availability : 6;
// };

// export interface Setup {
//     id:string;
//     description:string
//     setupType: SetupType2;
//     duration: TimeSpan;
//     durationTicks: number;
//     created: Date;
// }

export interface Turbine{
    id: string
    name: string
    min: number
    max: number
    priority: number
    isNew: boolean
}

export interface Plant{
    id: string
    name: string
    dropLossCoefficient: number
    centerTurbineMoh: number
    stepType: StepType
    step: number
    turbines: Turbine[],
    energyEquivalent?: number, 
    hrvWaterValue?: number,
    lrvWaterValue?: number,
    isNew: boolean
}

export interface User{
    id: string
    email: string
    authId: string
    userType: string
    isNew: boolean
}

export interface Company{
    id: string
    name: string
    vatNumber: string 
    email: string
    plants: Plant[]
    users: User[]
    isNew: boolean
}

export interface ExtendedJwtPayload {
    iss?: string;
    sub?: string;
    aud?: string[] | string;
    hpc_roles? : string[];
    exp?: number;
    nbf?: number;
    iat?: number;
    jti?: string;
}

export interface UserProfile{
    user: User
    companies: Company[]
    defaultCompany: Company
    defaultPlant: Plant
}

export type UserProfileC = {
    userProfile?: UserProfile
    setUserProfile:(u: UserProfile) => void
}

export type Auth = {
    token:string;
    isAuthenticated:() => boolean;
}

export interface CalculationUserSetup{
    frequency: Frequency
    startDate: Date
    EndDate: Date
    startHeight: number
    endHeight : number
}

export interface CalculationSetupBoundaries{
    minDate: Date
    maxDate: Date
    lrv: number
    hrv: number
    step: number
} 

export const StepTypePrint = (type : StepType) => {
	if(type === StepType.Flow)
        return "Flow";
    if(type === StepType.Step)
        return "Step";
    return "Unassigned";  
}


export const SetupCategoryPrint = (type : SetupCategory) => {
    if(type == SetupCategory.basicsetup)
        return "basicsetup";
    else if(type == SetupCategory.calculation)
        return "calculation";
    return "Unassigned";  
}

export const SetupTypePrint = (type: SetupType) => {
    if(type === SetupType.reservoir)
        return "Reservoir";
    else if(type === SetupType.inflow)
        return "Inflow";
    else if(type === SetupType.price)
        return "Price";
    else if(type === SetupType.turbinepower)
        return "Turbine efficiency";
    else if(type === SetupType.generatorpower)
        return "Generator efficiency";
    else if(type === SetupType.availability)
        return "Availability";
    return "Unassigned";  
}


// export interface Setup[
//     id: uuid, string Description, SetupType SetupType, TimeSpan Duration, long DurationTicks,
//     DateTime Created);

// export const FilterSetupsByCategory = (setupCategory : SetupCategory, setups : Setup[]) => {
//     if(setupCategory == SetupCategory.basicsetup){
//         return FilterBasicSetups(setups);
//     }else if(setupCategory == SetupCategory.calculation){
//         return FilterCalculationSetups(setups);
//     }
//     return setups;
// }
