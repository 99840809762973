import React, {FC, useState, useEffect, useContext } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { UserProfileContext } from '../App';
import { Plant } from "../common/types";
import { Grid } from "@mui/material";

const EditPlant: FC<{
  plant: Plant;
  setPlant: (plant: Plant) => void;
  setValid: (status: boolean) => void;
}> = ({ plant, setPlant, setValid }) => {

  const defaultValidation = {
    name: true,
    dropLossCoefficient: true,
    centerTurbineMoh: true,
    step: true,
    stepType: true,
    energyEquivalent: true,
    hrvWaterValue: true,
    lrvWaterValue: true
  };

  const helperTexts = {
    name: "1-50 characters",
    dropLossCoefficient: "E.g. 0.005",
    centerTurbineMoh: "E.g. 123.5 (m)",
    step: "E.g. 1.5 (m3/s)",
    numberOfSteps: "E.g. 10 steps pr turbine",
    energyEquivalent: "Energy equivalent (kWh/m3)",
    hrvWaterValue: "Hrv Water value (NOK/MWh)",
    lrvWaterValue: "Lrv Water value (NOK/MWh)"
    //stepTypeLabel: "Step type",
    //stepType: "Step or Flow"
  };

  const {userProfile} = useContext(UserProfileContext);

  const [values, setValues] = useState(plant); 
  const [validation, setValidation] = useState(defaultValidation);

  const [didMount, setDidMount] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, []);

  useEffect(() => {
    if(didMount){
      if(userProfile != null && userProfile.defaultPlant != null){
        setValues(userProfile.defaultPlant);
      }
    }
  }, [userProfile]);

  const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setValues({
      ...values,
      [name]: value,
      });
  };
 
  const nameRegex = new RegExp("^(?!\\s)(?!.*\\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$");
  const dropLossCoefficientRegex = new RegExp("^[]?([0-9]{1,2}([.][0-9]*)?)$");
  const centerTurbineMohRegex = new RegExp("^[]?([0-9]{1,3}([.][0-9]*)?)$");
  const stepRegex = new RegExp("^(?:[1-9]|[1-4][0-9]|5[0])$");
  const stepTypeRegex = new RegExp("(1)|(2)$");
  const energyEquivalentRegex = new RegExp("^[]?([0-9]{1,3}([.][0-9]*)?)$");
  const hrvWaterValueRegex = new RegExp("^[]?([0-9]{1,3}([.][0-9]*)?)$");
  const lrvWaterValueRegex = new RegExp("^[]?([0-9]{1,3}([.][0-9]*)?)$");
  
  useEffect(() => {
    if(didMount){
      const nameValid = nameRegex.test(values.name);
      setValidation({
        ...validation,
          name: nameValid
      });
    }
  }, [values.name]);


  useEffect(() => {
    if(didMount){
      const dropLossCoefficientValid = dropLossCoefficientRegex.test(values.dropLossCoefficient.toString());
      setValidation({
        ...validation,
          dropLossCoefficient: dropLossCoefficientValid
      });
    }
  }, [values.dropLossCoefficient]);


  useEffect(() => {
    if(didMount){
      const centerTurbineMohValid = centerTurbineMohRegex.test(values.centerTurbineMoh.toString());
      setValidation({
        ...validation,
        centerTurbineMoh: centerTurbineMohValid
      });
    }
  }, [values.centerTurbineMoh]);

  useEffect(() => {
    if(didMount){
      const stepValid = stepRegex.test(values.step.toString());
      setValidation({
        ...validation,
        step: stepValid
      });
    }
  }, [values.step]);

  useEffect(() => {
    if(didMount){
      const stepTypeValid = stepTypeRegex.test(values.stepType.toString());
      setValidation({
        ...validation,
        stepType: stepTypeValid
      });
    }
  }, [values.stepType]);

  useEffect(() => {
    if(didMount){
      const energyEquivalentValid = energyEquivalentRegex.test(values.energyEquivalent?.toString());
      setValidation({
        ...validation,
          energyEquivalent: energyEquivalentValid
      });
    }
  }, [values.energyEquivalent]);

  useEffect(() => {
    if(didMount){
      const hrvWaterValueValid = hrvWaterValueRegex.test(values.hrvWaterValue?.toString());
      setValidation({
        ...validation,
          hrvWaterValue: hrvWaterValueValid
      });
    }
  }, [values.hrvWaterValue]);

  useEffect(() => {
    if(didMount){
      const lrvWaterValueValid = lrvWaterValueRegex.test(values.lrvWaterValue?.toString());
      setValidation({
        ...validation,
          lrvWaterValue: lrvWaterValueValid
      });
    }
  }, [values.lrvWaterValue]);

  useEffect(() => {
    if(didMount){
      setPlantIfValidated();
    }
  }, [validation]);

  const setPlantIfValidated = () =>{
    if(values.name != '' && validation.name && 
      values.dropLossCoefficient != undefined && validation.dropLossCoefficient && 
      values.centerTurbineMoh != undefined && validation.centerTurbineMoh &&
      values.step != undefined && validation.step &&
      values.stepType != undefined && validation.stepType &&
      (values.energyEquivalent == undefined || values.energyEquivalent != undefined && validation.energyEquivalent) && 
      (values.hrvWaterValue == undefined || values.hrvWaterValue != undefined && validation.hrvWaterValue) && 
      (values.lrvWaterValue == undefined  || values.lrvWaterValue != undefined && validation.lrvWaterValue)){
      setPlant(values);
      setValid(true);
    }else{
        setValid(false);
    }
  }


  return (
    <React.Fragment>
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} color="text.secondary" gutterBottom>
          Enter details for this plant
        </Typography>
        
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
          <Grid container  >
            <Grid xs={2}>
                <TextField sx={{ mr: 1 }} required id="name-input" name="name" label="Name of plant" variant="outlined" value={values.name} onChange={handleInputChange} error={!validation.name} helperText={helperTexts.name}/>
            </Grid>
            <Grid xs={2}>
              <TextField sx={{ mr: 1 }} required id="dropLossCoefficient-input" name="dropLossCoefficient" label="Drop Loss Coefficient" variant="outlined" value={values.dropLossCoefficient} onChange={handleInputChange} error={!validation.dropLossCoefficient} helperText={helperTexts.dropLossCoefficient}/>
            </Grid>
            <Grid xs={2}>
              <TextField required id="centerTurbineMoh-input" name="centerTurbineMoh" label="Center turbine (meters over sea level)" variant="outlined" value={values.centerTurbineMoh} onChange={handleInputChange} error={!validation.centerTurbineMoh} helperText={helperTexts.centerTurbineMoh}/>          
            </Grid>
            <Grid xs={2}>
              <TextField sx={{ mr: 1 }} required id="step-input" name="step" label="Number of machine steps" variant="outlined" value={values.step} onChange={handleInputChange} error={!validation.step} helperText={helperTexts.numberOfSteps}/>
            </Grid>
            <Grid xs={2}>
              <TextField sx={{ mr: 1 }} id="step-input" name="energyEquivalent" label="Energy equivalent" variant="outlined" value={values.energyEquivalent} onChange={handleInputChange} error={!validation.energyEquivalent} helperText={helperTexts.energyEquivalent}/>
            </Grid>
            <Grid xs={2}>
              <TextField sx={{ mr: 1 }} id="step-input" name="hrvWaterValue" label="Hrv water value" variant="outlined" value={values.hrvWaterValue} onChange={handleInputChange} error={!validation.hrvWaterValue} helperText={helperTexts.hrvWaterValue}/>
            </Grid>
            <Grid xs={2}>
              <TextField sx={{ mr: 1 }} id="step-input" name="lrvWaterValue" label="Lrv water value" variant="outlined" value={values.lrvWaterValue} onChange={handleInputChange} error={!validation.lrvWaterValue} helperText={helperTexts.lrvWaterValue}/>
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
    </React.Fragment>
  );
}

export default EditPlant;