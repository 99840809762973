import React, {  useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import EditCompany from './EditCompany';
import EditAdminUsers from './EditAdminUsers';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import { AuthContext } from '../App';
import CircularProgress from '@mui/material/CircularProgress';
import { ErrorMessage } from '../common/Constant';


const steps = ['Company info', 'Admin users'];

EditCompanyStepper.propTypes = {
  defaultCompany: PropTypes.object,
  editCompanyCallback: PropTypes.func
};
  
export default function EditCompanyStepper({defaultCompany, editCompanyCallback}) {

  const authContext = useContext(AuthContext);

  const [company, setCompany] = useState(defaultCompany);
  const [admins, setAdmins] = useState(defaultCompany?.users);
  const [editMode, setEditMode] = useState(!defaultCompany.isNew);


  const [activeStep, setActiveStep] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(!editMode);
  
  const [companyValid, setCompanyValid] = useState(false);
  const [adminsValid, setAdminsValid] = useState(false);
  
  const [loading, setLoading] = useState(true);

  //const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});

  const [didMount, setDidMount] = useState(false);

  // const defaultUser = {
  //   "email": "",
  //   "blocked": false,
  //   "email_verified": false,
  //   "connection": "Username-Password-Authentication",
  //   "password": "ThisPasswordIsChangedOnLogin2023",
  //   "verify_email": true
  // };

  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true);
    setLoading(false);
  }, []);

  const handleNext = () => {
    if(activeStep == 0){
        // Save company info
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(editMode){
          setNextDisabled(false);
        }else{
          setNextDisabled(!adminsValid);
        }
        //setNextDisabled(!adminsValid);
    }
    if(activeStep == 1){
        submitForm();
    }
  };

  const resetValues = ()=>{
    setCompany(defaultCompany.company);
    setAdmins(defaultCompany?.users);
    setEditMode(!defaultCompany.isNew);
    setActiveStep(0);
  }

  const updateValues = (savedCompany)=>{
    setCompany(savedCompany.company);
    setAdmins(savedCompany?.users);
    setEditMode(!savedCompany.isNew);
    setActiveStep(0);
  }

  const handleCancel = () =>{
    resetValues();
    editCompanyCallback(null);
  }

  const handleFinish = (company) =>{
    updateValues(company);
    //handleEmptyDefaultCompany(company);
    editCompanyCallback(company);
  }

// TODO ?
//   const handleEmptyDefaultCompany = (savedPlant) =>{
//     if(userProfile.defaultCompany != null && userProfile.defaultPlant == null){
//       var newProfile = userProfile;
//       newProfile.defaultPlant = savedPlant;
//       newProfile.defaultCompany.plants.push(savedPlant);
//       setUserProfile(newProfile);
//     }
// }

  useEffect(() => {
    if(didMount){
      setNextDisabled(!companyValid);
    }
  }, [companyValid]);

  useEffect(() => {
    if(didMount){
      setNextDisabled(!adminsValid);
    }
  }, [adminsValid]);

  useEffect(() => {
    if(didMount){
      // Update turbines on plant and 
      setCompany({
        ...company,
        users: admins
      });
    }
  }, [admins]);
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if(editMode){
      setNextDisabled(false);
    }else{
      setNextDisabled(!companyValid);
    }
    //setNextDisabled(!companyValid);
  };

  const handleRejectionClose = () => {
    setRejection({open:false,message:""});
  }

  const submitForm = () => {
    fetch('api/companies',
        {
            method: "POST",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            },
            body: JSON.stringify(company)
        })
        .then(response => {
          if(response.ok) {
            response.json().then(company => {
              console.log("Company saved Ok: " + JSON.stringify(company));
              //createUser(company,admins);
              handleFinish(company);
            });
          }else if(response.status == 400){
            response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setRejection({open:true, message:"Internal error"});
       });
        
    }

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
      <Snackbar
        severity="error"
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
    
    <Card sx={{ width: '90%', p: 3}} >
      <Stepper activeStep={activeStep} sx={{ pb: 2}}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={{ maxHeight: 500, overflow: 'scroll'}} >
        {activeStep == 0 ? (
            <EditCompany company={company} setCompany={setCompany} setValid={setCompanyValid}/>
        ) : (
              <EditAdminUsers admins={admins} setAdmins={setAdmins} setValid={setAdminsValid} />
        )}
      </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            color="inherit"
            onClick={handleCancel}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button disabled={nextDisabled} onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
    </Card>
    </React.Fragment>
  );
}
