import React, {useState,useContext}  from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
//import DeleteConfirmation from '../components/DeleteConfirmation';
import Snackbar from '@mui/material/Snackbar';
import { AuthContext } from '../App';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ErrorMessage } from '../common/Constant';


DeletePlant.propTypes = {
  defaultPlant: PropTypes.object,
  deletePlantCallback: PropTypes.func
}

export default function DeletePlant({defaultPlant, deletePlantCallback}) {
  
    const authContext = useContext(AuthContext);
    const [open, setOpen] = useState(false);

    const [rejection, setRejection] = useState({open:false,message:""});

    const handleRejectionClose = () => {
        setRejection({open:false,message:""});
    }

    const submitForm = (plant) => {
    fetch('api/plants',
        {
            method: "DELETE",
            cache: "no-cache",
            headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authContext.token}`
            },
            body: JSON.stringify(plant)
        })
        .then(response => {
            if(response.ok) {
            response.json().then(plant => {
                console.log("plant deleted Ok: " + JSON.stringify(plant));
                deletePlantCallback(plant);
            });
            }else if(response.status == 400){
              response.json().then(error => {
                setRejection({open:true, message: ErrorMessage(error)});
              });
            }else{
              setRejection({open:true, message:"Server error"}); 
            }
        }).catch((err) => {
            console.log(err.message);
            setRejection({open:true, message:"Internal error"});
        });
    }

        
    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        submitForm(defaultPlant);
        setOpen(false);
    };

    const handleReject = () => {
        setOpen(false);
    };
 
  return (
    <React.Fragment>
         {/* <Snackbar
        severity="success"
        open={confirmation.open}
        autoHideDuration={3000}
        onClose={handleConfirmationClose}
        message={confirmation.message}
      /> */}
      <Snackbar
        severity="error"
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
      <IconButton aria-label="delete" onClick={() => setOpen(true)}>
        <DeleteIcon/>
      </IconButton>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete confirmation"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Are you shure you want to delete {defaultPlant.name} ?
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            This action is irreversible
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReject}>Disagree</Button>
          <Button onClick={handleConfirm} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      
    </React.Fragment>
  );
}
