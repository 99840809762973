import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment'
  
import PropTypes from 'prop-types';

DateValueLineChartBox.propTypes = {
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  label: PropTypes.string,
  values : PropTypes.array
}

export default function DateValueLineChartBox({title, showTitle, label, values}) {

  ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
        position: 'top',
        },
        title: {
        display: showTitle,
        text: title,
        },
    },
    };

const labels = values.map(result => moment(result.dateTime).format("DD/MM/YYYY"));

const data = {
labels,
datasets: [
    {
    label: label,
    data: values.map(result => result.value),
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
],
};

  return (
    <Box sx={{width: '100%'}}>
        <Line options={options} data={data} />
    </Box>
  );
}