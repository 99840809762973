import React from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ShowLineChart from './ShowLineChart';
import moment from 'moment'
import ShowMultipleBarChart from './ShowMultipleBarChart';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

CalculationTabs.propTypes = {
  calculationResult: PropTypes.object
};

export default function CalculationTabs({calculationResult}) {
  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //console.log("CalculationTabs optimalRoute:" + JSON.stringify(calculationResult));

  
  // const producedPowerLabel = "Produced power";
  const productionLabel = "Flow ㎥/s" ;
  const turbineGePowerLabel = "Power MW";
  const turbineProducedEnergyLabel = "Energy MW/h";
  const producedVolumeLabel = "Volume produced";
  const earningLabel = "Earning";
  const waterLevelLabel = "Water level (volume)";
  const priceLabel = "Price";
  const inflowLabel = "Inflow";
  const overflowLabel = "Overflow";


  const noTitleOptions = {
    display: false,
    text: ''
    }

  //   const flowTitleOptions = {
  //     display: true,
  //     text: '㎥/s'
  //     }

  // const mwTitleOptions = {
  //   display: true,
  //   text: 'MW'
  //   }

  const turbineColors = [
    {borderColor: "#1f2b94",backgroundColor:"#0a75f0"},
    {borderColor: "#4390ba",backgroundColor:"#0a94f0"},
    {borderColor: "#eb5234",backgroundColor:"#34b4eb"}
  ]

  const turbineProductionVolume = calculationResult.plantSetup.turbineSetups.map( (turbineSetup,index) => 
      {
        return  { 
            data: calculationResult.optimalRoute.map(result => result.stepResult.turbineRunConfigurations.filter(tc => tc.turbineId == turbineSetup.id)[0].flow),
            label: turbineSetup.name,
            borderColor: turbineColors[index].borderColor,
            backgroundColor: turbineColors[index].backgroundColor,
            fill: false,
          };
      }
    );

    const turbineGePower = calculationResult.plantSetup.turbineSetups.map( (turbineSetup,index) => 
    {
      return  { 
          data: calculationResult.optimalRoute.map(result => result.stepResult.turbineRunConfigurations.filter(tc => tc.turbineId == turbineSetup.id)[0].gePower),
          label: turbineSetup.name,
          borderColor: turbineColors[index].borderColor,
          backgroundColor: turbineColors[index].backgroundColor,
          fill: false,
        };
    }
  );

  const turbineProducedEnergy = calculationResult.plantSetup.turbineSetups.map( (turbineSetup,index) => 
  {
    return  { 
        data: calculationResult.optimalRoute.map(result => result.stepResult.turbineRunConfigurations.filter(tc => tc.turbineId == turbineSetup.id)[0].producedEnergy),
        label: turbineSetup.name,
        borderColor: turbineColors[index].borderColor,
        backgroundColor: turbineColors[index].backgroundColor,
        fill: false,
      };
  }
);

  const labels = calculationResult.optimalRoute.map(result => moment(result.timeSlot.start).format("DD/MM/YYYY"));
  const producedVolumeDatasets = {
    labels,
    datasets: [
        {
        label: producedVolumeLabel,
        data: calculationResult.optimalRoute.map(result => result.stepResult.volumeProduced),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
    };

  
  const turbineProductionVolumeDatasets = {
    labels,
    datasets : turbineProductionVolume
    };

    const turbineGePowerDatasets = {
      labels,
      datasets : turbineGePower
      };

    const turbineProducedEnergyDatasets = {
      labels,
      datasets : turbineProducedEnergy
      };

  // const producedPowerDatasets = {
  //   labels,
  //   datasets: [
  //       {
  //       label: producedPowerLabel,
  //       data: calculationResult.optimalRoute.map(result => result.stepResult.calcResult.producedEnergy),
  //       borderColor: "#c45850",
  //       backgroundColor:"#d78f89",
  //       },
  //   ],
  //   };

  const waterLevelDatasets = {
    labels,
    datasets: [
        {
        label: waterLevelLabel,
        data: calculationResult.optimalRoute.map(result => result.reservoirState.volume),
        borderColor: 'rgb(62,149,205)',
        backgroundColor: 'rgba(123,182,221, 0.5)',
        },
    ],
    };

  const priceDatasets = {
    labels,
    datasets: [
        {
        label: priceLabel,
        data: calculationResult.optimalRoute.map(result => result.price),
        borderColor: "#3cba9f",
        backgroundColor: "#71d1bd",
        },
    ],
    };
          
  const inflowDatasets = {
    labels,
    datasets: [
        {
        label: inflowLabel,
        data: calculationResult.optimalRoute.map(result => result.inflow),
        borderColor: "#ffa500",
        backgroundColor:"#ffc04d",
        },
    ],
    };

  const earningDatasets = {
    labels,
    datasets: [
        {
        label: earningLabel,
        data: calculationResult.optimalRoute.map(result => result.stepResult.calcResult.earning),
        borderColor: "#c45850",
        backgroundColor:"#d78f89",
        },
    ],
    };

    const overflowDatasets = {
      labels,
      datasets: [
          {
          label: overflowLabel,
          data: calculationResult.optimalRoute.map(result => result.stepResult.overflow),
          borderColor: "#c45850",
          backgroundColor:"#d78f89",
          },
      ],
      };


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="calculation results">
        <Tab label={productionLabel} {...a11yProps(0)} />
        <Tab label={turbineGePowerLabel} {...a11yProps(1)} />
        <Tab label={turbineProducedEnergyLabel} {...a11yProps(2)} />
          <Tab label={producedVolumeLabel} {...a11yProps(3)} />
          <Tab label={earningLabel} {...a11yProps(4)} />
          {/* <Tab label={producedPowerLabel} {...a11yProps(3)} /> */}
          <Tab label={waterLevelLabel} {...a11yProps(5)} />
          <Tab label={priceLabel} {...a11yProps(6)} />
          <Tab label={inflowLabel} {...a11yProps(7)} />
          <Tab label={overflowLabel} {...a11yProps(8)} />
        </Tabs>
      </Box>
      <ShowMultipleBarChart titleOptions={noTitleOptions} yTitleOptions={noTitleOptions} data={turbineProductionVolumeDatasets} value={value} index={0}/>
      <ShowMultipleBarChart titleOptions={noTitleOptions} yTitleOptions={noTitleOptions} data={turbineGePowerDatasets} value={value} index={1}/>
      <ShowMultipleBarChart titleOptions={noTitleOptions} yTitleOptions={noTitleOptions} data={turbineProducedEnergyDatasets} value={value} index={2}/>
      <ShowLineChart titleOptions={noTitleOptions} yTitleOptions={noTitleOptions} data={producedVolumeDatasets} value={value} index={3}/>
      <ShowLineChart titleOptions={noTitleOptions} yTitleOptions={noTitleOptions} data={earningDatasets} value={value} index={4}/>
      {/* <ShowLineChart titleOptions={noTitleOptinos}  data={producedPowerDatasets} value={value} index={3}/> */}
      <ShowLineChart titleOptions={noTitleOptions} yTitleOptions={noTitleOptions} data={waterLevelDatasets} value={value} index={5}/>
      <ShowLineChart titleOptions={noTitleOptions} yTitleOptions={noTitleOptions} data={priceDatasets} value={value} index={6}/>
      <ShowLineChart titleOptions={noTitleOptions} yTitleOptions={noTitleOptions} data={inflowDatasets} value={value} index={7}/>
      <ShowLineChart titleOptions={noTitleOptions} yTitleOptions={noTitleOptions} data={overflowDatasets} value={value} index={8}/>
    </Box>
  );
}