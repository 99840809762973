import React, {useState, useEffect, useContext}  from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {AuthContext, UserProfileContext } from '../App';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';

export default function UserProfileMenu() {
  
    const { userProfile, setUserProfile } = useContext(UserProfileContext);

    //const { user, isAuthenticated } = useAuth0();
    const { user} = useAuth0();
    const authContext = useContext(AuthContext);

    const [didMount, setDidMount] = useState(false);
    const [anchorC, setAnchorC] = useState(false);
    const [anchorP, setAnchorP] = useState(false);
   
    const [selectedIndexC] = useState(0);
    const [selectedIndexP] = useState(0);
    
    const openC = Boolean(anchorC);
    const openP = Boolean(anchorP);

  useEffect(() => {
   loadUserProfile();
  }, [user?.sub]);

    const theme = createTheme({
        components: {
          // Name of the component
          MuiButton: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                fontSize: '0.7rem',
                color: '#fff',
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                fontSize: '0.8rem'
              },
            },
          },
        },
      });
      

        // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true) 
    //loadUserProfile();
  }, [])

  useEffect(() => { 
    if(didMount && userProfile == null){
      loadUserProfile();
    }
  }, [userProfile])

    
    const handleClickC = (event) => {
      if(userProfile?.companies?.length > 1 ){
        setAnchorC(event.currentTarget);
      }
    };

    const handleClickP = (event) => {
      if( userProfile?.defaultCompany?.plants.length > 1 ){
        setAnchorP(event.currentTarget);
      }
    };

    const handleCloseC = () => {
        setAnchorC(null);
    };

    const handleCloseP = () => {
        setAnchorP(null);
    };

  
    const handleMenuItemClickP = (event,index) => {
        setUserProfile((profile) => {
            return {
              ...profile,
              defaultPlant: profile.defaultCompany.plants[index]
            }
          })
        setAnchorP(null);
      };

      const handleMenuItemClickC = (event,index) => {
        setUserProfile((profile) => {
            return {
              ...profile,
              defaultCompany: profile.companies[index],
              defaultPlant: profile.companies[index].plants[0]
            }
          })
        setAnchorC(null);
      };

    const loadUserProfile = () => {
        fetch('api/UserProfile/' + user.name, 
        {
          headers: { Authorization: `Bearer ${authContext.token}` }
        })
        .then(response => {
            if(response.ok) return response.json();
            throw new Error("Network response was not ok");
          })
          .then(userProfile => {
            setUserProfile(userProfile);
          })
          .catch((error) => {
            setUserProfile(null);
            console.log(error.message);
          });
    }

    const getEndIconC = () => {
      // 
      if(userProfile?.companies?.length > 1   ){
        return <KeyboardArrowDownIcon/>
      }else if(userProfile?.companies?.length == 1 && userProfile?.defaultCompany?.plants.length > 0){
        return <KeyboardArrowDownIcon/>
      }
      return "";
    }

    const getEndIconP = () => {
      if( userProfile?.defaultCompany?.plants.length > 1 ){
        return <KeyboardArrowDownIcon/>
      }
      return "";
    }
    return (userProfile == null ? 
        <React.Fragment/>
        :
        <React.Fragment>
          <ThemeProvider theme={theme}>
          <Button
              id="company-button"
              aria-controls={openC ? 'company-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openC ? 'true' : undefined}
              onClick={handleClickC}
              endIcon={getEndIconC()}
          >
              {userProfile.defaultCompany?.name}
          </Button>
          <Menu
              id="company-menu"
              aria-labelledby="company-button"
              anchorEl={anchorC}
              open={openC}
              onClose={handleCloseC}
          >
              {userProfile.companies?.map((company,index) => (
                  <MenuItem key={company.id}
                  selected={index === selectedIndexC}
                  onClick={(event) => handleMenuItemClickC(event, index)} 
                  >{company.name}</MenuItem>
              ))}
          </Menu>
          
          {userProfile?.defaultCompany?.plants.length > 0 &&
            <React.Fragment>
            <Button
                id="plant-button"
                aria-controls={openP ? 'plant-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openP ? 'true' : undefined}
                onClick={handleClickP}
                endIcon={getEndIconP()}
            >
                {userProfile.defaultPlant?.name}
            </Button>
            <Menu
                id="plant-menu"
                aria-labelledby="plant-button"
                anchorEl={anchorP}
                open={openP}
                onClose={handleCloseP}
            >
                {/* onClick={() => handleCloseP({plant})} */}
                {userProfile.defaultCompany?.plants?.map((plant,index) => (
                    <MenuItem key={plant.id} 
                    selected={index === selectedIndexP}
                    onClick={(event) => handleMenuItemClickP(event, index)}
                    >{plant.name}</MenuItem>
                ))}
            </Menu>
            </React.Fragment>
          }
          
          </ThemeProvider>
        </React.Fragment>
    );
}