import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

EditCompany.propTypes = {
  company: PropTypes.object,
  setCompany: PropTypes.func,
  setValid: PropTypes.func
};

export default function EditCompany({company, setCompany, setValid}) {
  
  //const newCompanyContext = useContext(CompanyContext);

  const defaultValidation = {
    name: true,
    email: true,
    vatNumber: true
  };

  const helperTexts = {
    name: "1-50 characters",
    email: "aaa@gmail.com",
    vatNumber: "9-digits"
  };

  const [values, setValues] = useState(company);
  const [validation, setValidation] = useState(defaultValidation);
  
  const [didMount, setDidMount] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValues({
      ...values,
      [name]: value,
      });

  };
 
  const nameRegex = new RegExp("^(?!\\s)(?!.*\\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$");
  const emailRegex = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
  const vatNumberRegex = new RegExp("^[0-9]{9}$");
  

  useEffect(() => {
    if(didMount){
      const nameValid = nameRegex.test(values.name);
      setValidation({
        ...validation,
          name: nameValid
      });
    }
  }, [values.name]);

  useEffect(() => {
    if(didMount){
      const emailValid = emailRegex.test(values.email);
      setValidation({
        ...validation,
          email: emailValid
      });
    }
  }, [values.email]);

  useEffect(() => {
    if(didMount){
      const vatNumberValid = vatNumberRegex.test(values.vatNumber);
      setValidation({
        ...validation,
          vatNumber: vatNumberValid
      });
    }
  }, [values.vatNumber]);

  useEffect(() => {
    if(didMount){
      setCompanyIfValidated();
    }
  }, [validation]);

  const setCompanyIfValidated = () =>{
    if(values.name != '' && validation.name && 
      (values.email == '' || 
      values.email != '' && validation.email) && 
      values.vatNumber != '' && validation.vatNumber){
        setCompany(values);
        setValid(true);
    }else{
      setValid(false);
    }
  }


  return (
    <div>
    <Card >
      <CardContent>
        <Typography sx={{ fontSize: 14, mb: 1.5  }} color="text.secondary" gutterBottom>
          Enter details for new company
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
            <TextField fullWidth required id="name-input" name="name" label="Name" variant="outlined" value={values.name} onChange={handleInputChange} error={!validation.name} helperText={helperTexts.name}/>
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
            <TextField id="email-input" name="email" label="Email" variant="outlined" value={values.email} onChange={handleInputChange} error={!validation.email} helperText={helperTexts.email}/>
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
            <TextField required id="vatNumber-input" name="vatNumber" label="VAT number" variant="outlined" value={values.vatNumber} onChange={handleInputChange} error={!validation.vatNumber} helperText={helperTexts.vatNumber}/>
        </Typography>
      </CardContent>
    </Card>
    </div>
  );
}

