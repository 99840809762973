import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import SetupAccordion from "./SetupAccordion";
import { FilterSetupsByCategory } from "../common/Constant";
import { FrequencyPrint, SetupCategory, SetupLevel, SetupType } from "../common/types";

ShowSetups.propTypes = {
  frequency: PropTypes.number,
  setupDesc: PropTypes.object,
  setupUpdateCallback: PropTypes.func,
  setupCategory: PropTypes.number,
  setupLevel: PropTypes.number
};

export default function ShowSetups({frequency, setupDesc, setupUpdateCallback, setupCategory, setupLevel}) {
  
    const setupsByCategory = FilterSetupsByCategory(setupCategory,setupDesc.setups);

    const getSetupLevel = () => {
      if(setupLevel == SetupLevel.turbine){
        return "machine"
      }else if(setupLevel == SetupLevel.plant){
        return "plant"
      }else if(setupLevel == SetupLevel.company){
        return "company"
      }else{
        return "unassigned"
      }
    }

    const noReservoirSetupForPlant = () => {
     if(setupLevel == SetupLevel.plant && 
            (setupsByCategory.filter(setup => setup.setupType == SetupType.reservoir).length == 0)){
              return <Typography sx={{ fontSize: 14 }} color="error.main" gutterBottom>
                      - Plant must have a reservoir setup
                    </Typography>
      }
      return "";
    }

    const noPriceSetupForPlant = () => {
      if(setupLevel == SetupLevel.plant && 
             (setupsByCategory.filter(setup => setup.setupType == SetupType.price).length == 0)){
               return <Typography sx={{ fontSize: 14 }} color="error.main" gutterBottom>
                       - Plant must have a {FrequencyPrint(frequency)} price setup
                     </Typography>
       }
       return "";
     }

     const noInflowSetupForPlant = () => {
      if(setupLevel == SetupLevel.plant && 
             (setupsByCategory.filter(setup => setup.setupType == SetupType.inflow).length == 0)){
               return <Typography sx={{ fontSize: 14 }} color="error.main" gutterBottom>
                       - Plant must have a {FrequencyPrint(frequency)} inflow setup
                     </Typography>
       }
       return "";
     }

  
    return (
      <React.Fragment>
        <Card sx={{ m: 2, minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            Setups for {getSetupLevel()}: {setupDesc.name}
          </Typography>
          {setupCategory == SetupCategory.basicsetup && noReservoirSetupForPlant()}
          {setupCategory == SetupCategory.calculation && noPriceSetupForPlant()}
          {setupCategory == SetupCategory.calculation && noInflowSetupForPlant()}
            {setupsByCategory.length > 0 &&
            <Box sx={{ p:1}} >
                <SetupAccordion frequency={frequency} setups={setupsByCategory} setupCategory={setupCategory} setupUpdateCallback={setupUpdateCallback}/>
            </Box>
             }
            </CardContent>
            </Card>
      </React.Fragment>
    );
}
