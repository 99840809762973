import React, {FC, useState, useEffect,useContext } from "react";
import { Card, CardContent, CircularProgress, Snackbar, Stack, Typography } from "@mui/material";
import {AuthContext} from '../App';
import {UserProfileContext} from '../App';
import { ErrorMessage } from "../common/Constant";
import { Company } from "../common/types";

const CreateDemoCompany: FC<{
  editCallback: (company:Company) => void;
}> = ({ editCallback }) => {

  
  const authContext = useContext(AuthContext);
  const {userProfile} = useContext(UserProfileContext);
  const [rejection, setRejection] = useState({open:false,message:""});
  const [didMount, setDidMount] = useState(true);

  //const [didMount, setDidMount] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true)
    submitForm();
  }, []);

  useEffect(() => {
    if(didMount){
      if(userProfile != null && userProfile.defaultCompany != null){
        //submitForm(getDemoPlant());
      }
    }
  }, [userProfile]);

  const submitForm = () => {
    fetch('api/companies/democompany',
        {
            method: "POST",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            }
        })
        .then(response => {
          if(response.ok) {
            response.json().then(company => {
              editCallback(company);
            });
          }
          else if(response.status == 400){
            response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setRejection({open:true, message:"Internal error"});
      });
    }

    const handleRejectionClose = () => {
      setRejection({open:false,message:""});
      editCallback(null);
    }

  return (

    <React.Fragment>
   
    <Card>
      <CardContent>
      <Stack direction="row">
      <Typography sx={{ fontSize: 14, mb: 1.5 }} color="text.secondary" gutterBottom>
          Creating demo company
        </Typography><CircularProgress sx={{ ml: 1 }}/>
      </Stack>
      </CardContent>
    </Card>
    <Snackbar
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
    </React.Fragment>
  );
}

export default CreateDemoCompany;