import React, { useState, useEffect, useMemo, createContext } from 'react';
import { useNavigate } from "react-router-dom";
import './App.css'; 
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Navigate, Route, Routes} from "react-router-dom";
import CompaniesTable from './company/CompaniesTable';
import PlantsTable from './plant/PlantsTable';
import PlantDetail from './plant/PlantDetail';
import AdHocCalculation from './calculation/AdHocCalculation';
import Profile from './pages/Profile';
import SpecsDetail from './setupImport/SpecsDetail';
import SetupsDetail from './setup/SetupsDetail';
import HomePage from './pages/HomePage';
// import HomePageLoginDenied from './pages/HomePageLoginDenied';
import AccountMenu from './menu/AccountMenu';
import UserProfileMenu from './components/UserProfileMenu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import logo from './logo-white-appbar.svg';
import { Link as RouterLink} from 'react-router-dom';
import AccountTree from '@mui/icons-material/AccountTree';
import UploadFile from '@mui/icons-material/UploadFile';
import Home from '@mui/icons-material/Home';
import AddHome from '@mui/icons-material/AddHome';
import SettingsSuggest from '@mui/icons-material/SettingsSuggest';
import QueryStats from '@mui/icons-material/QueryStats';

import {Auth, Company, SetupCategory, UserProfileC} from './common/types'; //ExtendedJwtPayload,
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import CreateDemoCompany from './company/CreateDemoCompany';

export const AuthContext = createContext<Auth>({token:'', isAuthenticated: () => false});
export const UserProfileContext = createContext<UserProfileC>({
  userProfile: undefined
  ,
  setUserProfile: () => {},
});

const drawerWidth = 240;
    
function App(props: { history?: any; window?: any; }) {

const { user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
const [token, setToken] = useState(null);

const { window } = props;
const [userProfile, setUserProfile] = useState(null);
const [didMount, setDidMount] = useState(false);
const [mobileOpen, setMobileOpen] = useState(true);
const [rolesClaim,setRolesClaim] = useState(null);
const [expires,setExpires] = useState(null);

useEffect(() => {
  const idTokenClaims = async () => {
    let claims: IdToken;
    try {
      claims = await getIdTokenClaims();
    } catch (e) {
        console.error(e);
    }
    return claims;
  };
  if(user){
    idTokenClaims().then(idToken => {
      if(idToken.exp){
        setExpires(idToken.exp);
      }

      if(idToken.hpc_roles){
        setRolesClaim(idToken.hpc_roles);
      } 
    });  
  }
 }, [getIdTokenClaims, user?.sub]);

useEffect(() => {
  const getAccessToken = async () => {
    let token;
    try {
        token = await getAccessTokenSilently();
    } catch (e) {
        if (e.error === 'login_required') {
            // if silent auth fails, fall back to popup
            token = await getAccessTokenWithPopup();
        } else {
            console.error(e);
        }
    }
    return token;
  };
  if(user){
    getAccessToken().then(token => {
      setToken(token);
      // let decoded = jwtDecode<ExtendedJwtPayload>(token);      
      
      // if(decoded.exp){
      //   setExpires(decoded.exp);
      // }

      // if(decoded.hpc_roles){
      //   setRolesClaim(decoded.hpc_roles);
      // } 
    });  
  }
 }, [getAccessTokenSilently, user?.sub]);



const userHasRoles = (roles:string[]): boolean => {
    if(roles == undefined || roles.length === 0){
      return false;
    }
    if(rolesClaim == null || rolesClaim.length === 0)
      return false;
    return roles.some(role => rolesClaim.includes(role)); 
  }
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const userProfileContext = useMemo(
    () => ({ userProfile, setUserProfile }), 
    [userProfile]
  );

  const authContext = 
  {
      token:token, 
      isAuthenticated: () => {
        if(!expires)
          return false;
        let idTokenHasExpired = new Date().getTime()/1000 > expires;
        //console.log("idTokenHasExpired: is " + new Date().getTime()/1000 + " > " + expires + " " + idTokenHasExpired);
        return isAuthenticated && !idTokenHasExpired;
      }
    };

  useEffect(() => { 
    setDidMount(true);
  }, []);

  useEffect(() => { 
    if(didMount){
      console.log("App userProfile changed from child. ");
    }
  }, [userProfile])


  const navigate = useNavigate();

  const handleDemoCompany = (company: Company): void => {
    console.log("Company added:" + JSON.stringify(company));
    setUserProfile(null);
    navigate("/editplants");
  }

  const drawer = (
    <div>
      <Toolbar/>
      { authContext.isAuthenticated() &&
      <List>
        {userHasRoles(["superuser"]) &&
        <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/editcompanies" onClick={handleDrawerToggle}>
                  <ListItemIcon>
                  <AccountTree fontSize="small" />
                  </ListItemIcon>
                <ListItemText primary="Companies" />
              </ListItemButton>
            </ListItem>
        }
        
        {userHasRoles(["superuser", "admin"]) &&
        <React.Fragment>
        <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/editplants" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <AddHome fontSize="small" />
          </ListItemIcon>
            <ListItemText primary="Plants" />
            </ListItemButton>
          </ListItem>

           <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/plant" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <Home fontSize="small" />
          </ListItemIcon>
            <ListItemText primary="Plant" />
            </ListItemButton>
          </ListItem> 

          <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/specsdetail" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <SettingsSuggest fontSize="small" />
          </ListItemIcon>
            <ListItemText primary="Import specification" />
            </ListItemButton>
          </ListItem> 

          <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/basicsetups" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <UploadFile fontSize="small" />
          </ListItemIcon>
            <ListItemText primary="Basic plant setups" />
            </ListItemButton>
          </ListItem> 

          <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/calculation" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <QueryStats fontSize="small" />
          </ListItemIcon>
            <ListItemText primary="Calculation" />
            </ListItemButton>
          </ListItem> 
          </React.Fragment>
        }

        <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/createdemocompany" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <QueryStats fontSize="small" />
          </ListItemIcon>
            <ListItemText primary="Create demo company" />
            </ListItemButton>
          </ListItem>
      </List>
    }
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    
    <AuthContext.Provider value={authContext}>
    <UserProfileContext.Provider value={userProfileContext}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
             sx={{ mr: 2, display: { sm: 'none' } }}
          >
          <MenuIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            component={RouterLink} to="/"
            className="fixedHeaderContainer"
            >
              <img src={logo} alt="PowerQanalytics"/>
          </IconButton>
         
          <Typography variant="h6" component="div" sx={{ flexGrow: 0, mr: 2 ,display: { xs: 'none', sm: 'block' } }}>
            PowerQanalytics
          </Typography>
            {authContext.isAuthenticated() && <UserProfileMenu/>}
            <Box sx={{ ml: "auto" }}></Box>
            <AccountMenu/>
        </Toolbar>
      </AppBar>
      {authContext.isAuthenticated() &&
      (
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="menu"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
          {/* <Box sx={{
          height: '100vh',
          borderRight: 1,
          borderColor: 'grey.500',
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          >
          {drawer}
        </Box> */}
      </Box>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, height: '100vh', overflow: 'scroll' }}
      >
        <Toolbar />
        <div>
          <Routes>
          <Route path="/" element={<HomePage {...props} />}/>
              
          {authContext.isAuthenticated() &&
                              userHasRoles(["superuser"]) &&
          <Route path="/editcompanies" element={<CompaniesTable />}/>
          }
          {authContext.isAuthenticated() &&
                                userHasRoles(["superuser", "admin"]) &&
          <React.Fragment>
          <Route path="/editplants" element={<PlantsTable/>}/>
          <Route path="/plant" element={<PlantDetail/>}/>
          <Route path="/specsdetail" element={<SpecsDetail/>}/>
          <Route path="/basicsetups" element={<SetupsDetail setupCategory={SetupCategory.basicsetup}/>}/>
          <Route path="/calculation" element={<AdHocCalculation />}/>
          <Route path="/profile" element={<Profile />}/>
          </React.Fragment>
          }
          {authContext.isAuthenticated() && userHasRoles(["superuser"]) &&
          <Route path="/createdemocompany" element={<CreateDemoCompany editCallback={handleDemoCompany}/>}/>
          }
          {/* <Route path="/?error=access_denied&error_description=Access%20to%20HydroPowerCalc%20is%20not%20allowed.&state=ZDE0YWtVNHVLT2pISk1fcWRXLlJIVG9ofjhFfmRHNlh1WUtUU3lwfk9sUA%3D%3D" element={<h2>Access denied</h2>}/> */}
          <Route path="*" element={<Navigate to="/" />}/>
          </Routes>
          </div>
      </Box>
    </Box>
    </UserProfileContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
