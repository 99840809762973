import React, { useState, useEffect, FC } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Turbine } from "../common/types";

const EditTurbine: FC<{
  turbine: Turbine;
  setTurbine: (turbine: Turbine) => void;
  setValid: (turbine: Turbine, status: boolean) => void;
}> = ({ turbine, setTurbine, setValid }) => {  

  const defaultValidation = {
    name: true,
    min: true,
    max: true
  };

  const helperTexts = {
    name: "1-50 characters",
    min: "Floating number e.g. 1.5",
    max: "Floating number e.g. 10"
  };

  const [values, setValues] = useState(turbine);
  const [validation, setValidation] = useState(defaultValidation);
  
  const [didMount, setDidMount] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])

  const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setValues({
      ...values,
      [name]: value,
      });

  };
 
  const nameRegex = new RegExp("^(?!\\s)(?!.*\\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,50}$");
  const minRegex = new RegExp("^([0-9]{1,3}[.])?[0-9]{1,3}$");
  const maxRegex = new RegExp("^([0-9]{1,3}[.])?[0-9]{1,3}$");
  
  // const minSmallerThanMax = (min: number, max: number) => {
  //   return min < max;
  // }
  
  useEffect(() => {
    if(didMount){
      const nameValid = nameRegex.test(values.name);
      setValidation({
        ...validation,
          name: nameValid
      });
    }
  }, [values.name]);

  useEffect(() => {
    if(didMount){
      const minValid = minRegex.test(values.min.toString());
      //const min_max_validation = minSmallerThanMax(values.min,values.max);

      // if(values.min != undefined && values.max != undefined){
      //   setValidation({
      //     ...validation,
      //     min: minValid && min_max_validation
      //   });
      // }else{
        setValidation({
          ...validation,
          min: minValid
        });
      //}
    }
  }, [values.min]);

  useEffect(() => {
    if(didMount){
      const maxValid = maxRegex.test(values.max.toString());
      // const min_max_validation = minSmallerThanMax(values.min,values.max);

      // if(values.min != undefined && values.max != undefined){
      //   setValidation({
      //     ...validation,
      //     max: maxValid && min_max_validation
      //   });
      // }else{
        setValidation({
          ...validation,
          max: maxValid
        });
      //}
    }
  }, [values.max]);

  useEffect(() => {
    if(didMount){
      setTurbineIfValidated();
    }
  }, [validation]);

  const setTurbineIfValidated = () =>{
    if(values.name != undefined && validation.name && 
      values.min != undefined && validation.min && 
      values.max != undefined && validation.max){ 
        setTurbine(values);
        setValid(values,true);
      }else{
        setValid(values,false);
      }
    }

  return (
    <div>
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14, mb: 1.5  }} color="text.secondary" gutterBottom>
          Enter details for this machine
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
            <TextField sx={{ mr: 1 }} required id="name-input" name="name" label="Name" variant="outlined" value={values.name} onChange={handleInputChange} error={!validation.name} helperText={helperTexts.name}/><TextField sx={{ mr: 1 }} required id="min-input" name="min" label="Min flow m3/s" variant="outlined" value={values.min} onChange={handleInputChange} error={!validation.min} helperText={helperTexts.min}/><TextField required id="max-input" name="max" label="Max flow m3/s" variant="outlined" value={values.max} onChange={handleInputChange} error={!validation.max} helperText={helperTexts.max}/>
        </Typography>
      </CardContent>
    </Card>
    </div>
  );
}

export default EditTurbine;