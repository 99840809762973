import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { SetupCategory, SetupType } from './types';


export const FilterSetupsByCategory = (setupCategory , setups) => {
    if(setupCategory == SetupCategory.basicsetup){
        return FilterBasicSetups(setups);
    }else if(setupCategory == SetupCategory.calculation){
        return FilterCalculationSetups(setups);
    }
    return setups;
}

export const FilterBasicSetups = (setups) => {
    return setups.filter(setup => 
        setup.setupType == SetupType.reservoir ||
        setup.setupType == SetupType.turbinepower ||
        setup.setupType == SetupType.generatorpower 
    );
}

export const FilterCalculationSetups = (setups) => {
    return setups.filter(setup => 
        setup.setupType == SetupType.price ||
        setup.setupType == SetupType.inflow ||
        setup.setupType == SetupType.availability
    );
}



export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  export const formatDateTime = (setup) => {
    return moment(setup.created).format("DD/MM/YYYY HH:mm:ss");
   }


   export const ErrorMessage = (error) => {
    //if(errorMsg == null || errorMsg.length == 0 && error.errors != null ) errorMsg = error.errors[0]
    if(error.message == null || error.code == null && error.errors != null ){
        let msg = JSON.stringify(error.errors);
        return msg;
    }else{
        return error.message + " (" + error.code + ")";
    }
   }