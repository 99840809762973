import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import PropTypes from 'prop-types';
//import QueryStats from '@mui/icons-material/QueryStats';
import Button from '@mui/material/Button';
//import EditSpecs from '../setupImport/EditSpecs';
import EditPlantSpecs from './EditPlantSpecs';
//import {SetupType} from '../common/types';

 EditSpecsBackdrop.propTypes = {
   editCallback: PropTypes.func,
   //specs: PropTypes.object
 }

export default function EditSpecsBackdrop({ editCallback}) {


  const [open, setOpen] = React.useState(false);

  const handleClose = (plantSpec) => {
    setOpen(false);
    editCallback(plantSpec);
  };

  const handleToggle = () => {
    setOpen(!open);
  };


  return (
    <React.Fragment>
      <Button aria-label="edit" onClick={() => handleToggle()} sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
        Edit import specification for Plant
      </Button>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        {open == true && 
        (<EditPlantSpecs editCallback={handleClose}/>)
        }
        
      </Backdrop>
    </React.Fragment>
  );
}
