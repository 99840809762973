import React, { useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import SetupCard from './SetupCard';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ShowChartIcon from '@mui/icons-material/ShowChart';

SetupBackdrop.propTypes = {
  setup : PropTypes.object
}

export default function SetupBackdrop({setup}) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <IconButton aria-label="showCalculation" onClick={() => handleToggle()}>
       <ShowChartIcon/>
      </IconButton>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <SetupCard setup={setup} />
      </Backdrop>
    </React.Fragment>
  );
}