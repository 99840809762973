import React, { useState, useEffect, useContext } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from "@mui/material";
//import Typography from '@mui/material/Typography';
import {AuthContext, UserProfileContext } from '../App';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import EditPlantBackdrop from "./EditPlantBackdrop";
import ShowTurbinesTable from "./ShowTurbinesTable";
import Snackbar from '@mui/material/Snackbar';
import ShowPlantBasicCard from "./ShowPlantBasicCard";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ErrorMessage } from "../common/Constant";

export default function PlantDetail() {

  const authContext = useContext(AuthContext);
  const {userProfile} = useContext(UserProfileContext);

  const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plant, setPlant] = useState(null);
  const [editPlant, setEditPlant] = useState(null);

  const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});

  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true)
    getUpdatedPlant(); 
  }, [])
  

  useEffect(() => {
    if(didMount){
      setLoading(true);
      getUpdatedPlant();
    }
  }, [userProfile]);

  const getUpdatedPlant = () => {
    if(userProfile != null && userProfile.defaultPlant != null){
      updatePlant(userProfile.defaultPlant.id);
    }else{
      console.log("PlantDetail -> userProfile is missing defaultPlant");
      setPlant(null);
      setLoading(false);
    }
  }


  const handleEditPlant = (plant) => {
    if(plant != null){
      getUpdatedPlant();
      setConfirmation({open:true,message:"Plant was saved: " + plant.name});
    }
    setEditPlant(null);
  }

  const updatePlant = (id) => {
    fetch('api/plants/plant/' + id,
        {
            method: "GET",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            }
        })
        .then(response => {
          if(response.ok) {
            response.json().then(plant => {
              setPlant(plant);
              setLoading(false);
            });
          }else if(response.status == 400){
            response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setRejection({open:true, message:"Internal error"});
      });
    }

    const handleConfirmationClose = () => {
      setConfirmation({open:false,message:""});
    }

    const handleRejectionClose = () => {
      setRejection({open:false,message:""});
    }

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
      <Snackbar
        severity="success"
        open={confirmation.open}
        autoHideDuration={3000}
        onClose={handleConfirmationClose}
        message={confirmation.message}
      />
       <Snackbar
        severity="error"
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
      {plant ?
      (<Card sx={{ m: 0 }}>
        <React.Fragment>
        <CardContent>
        <ShowPlantBasicCard plant={plant}/>
        <ShowTurbinesTable turbines={plant.turbines}/>
        </CardContent>
        <CardActions disableSpacing sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
        <IconButton aria-label="edit" onClick={() => setEditPlant(plant)} >
          <EditIcon/>
        </IconButton>
        {editPlant != null &&
          <EditPlantBackdrop defaultPlant={plant} editPlantCallback={handleEditPlant} />
        }
        </CardActions>
        </React.Fragment>
      </Card>) 
      :
         <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          This company has no plants
        </Typography>
      }
    
    </React.Fragment>
  );
  }