import React, { useState, useContext } from "react";
//import Typography from '@mui/material/Typography';
import {AuthContext, UserProfileContext } from '../App';
//import CircularProgress from '@mui/material/CircularProgress';
//import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Snackbar from '@mui/material/Snackbar';
import { Box, CircularProgress, Stack } from "@mui/material";
import axios from 'axios';
import PropTypes from 'prop-types';
import { ErrorMessage } from "../common/Constant";
import { SetupCategory, SetupCategoryPrint } from "../common/types";

SetupImport.propTypes = {
  setupImportCallback: PropTypes.func,
  setupCategory: PropTypes.number
};


export default function SetupImport({setupCategory, setupImportCallback}) {

  const authContext = useContext(AuthContext);
  const {userProfile} = useContext(UserProfileContext);

  //const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  
  const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});

  const onFileChange = (event) => {
      var file = event.target.files[0];
      setFile(file);
    }

  const handleUpload = () => {
    if(loading == false){
      setLoading(true);
      const formData = new FormData();
      formData.append("formFile", file);
      uploadExcel(formData);
    }else{
      setRejection({open:true, message:"Import is running. Please wait."}); 
    }
  }


  const uploadExcel = (formData) => {
    if(userProfile == null){
      console.log("uploadExcel - userprofile is null");
      return;
    }

    axios({method: 'post',    
		url: 'api/ImportExcel/' + SetupCategoryPrint(setupCategory) + '/plant/' + userProfile.defaultPlant.id, 
		data: formData,
		headers: {'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${authContext.token}` }
		})
    .then(response => {
          if(response.status == 200) {
            const setups = response.data;
              setupImportCallback();
              setConfirmation({open:true, message: "File uploaded with " + setups.length + " setups"});
          }else if(response.status == 400){
            response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
          if(err.response.status == 400){
            setRejection({open:true, message: err.response.data.message});
          }else{
            setRejection({open:true, message:"Internal error"});  
          }
      });
    }

    const handleConfirmationClose = () => {
      setConfirmation({open:false,message:""});
    }

    const handleRejectionClose = () => {
      setRejection({open:false,message:""});
    }

    const getFileUploadButtonText = () => {
      if(setupCategory == SetupCategory.basicsetup){
        return 'Upload basic setups';
      }
      return 'Upload calculation setups';
    }

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
        <Snackbar
          severity="success"
          open={confirmation.open}
          autoHideDuration={3000}
          onClose={handleConfirmationClose}
          message={confirmation.message}
        />
        <Snackbar
          severity="error"
          open={rejection.open}
          onClose={handleRejectionClose}
          message= {rejection.message}
        />
        <Stack direction="row" sx={{display: "flex", justifyContent: "flex-end"}}>
					<input type="file" onChange={onFileChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
          {file != null  &&
            <IconButton aria-label="add" onClick={handleUpload} >
              {getFileUploadButtonText()} <FileUploadIcon/>
            </IconButton>
          }
        </Stack>
        
      </React.Fragment>
  );
}