import React, { useState, useEffect, useContext } from "react";
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import { CardActions } from "@mui/material";
//import Typography from '@mui/material/Typography';
import {AuthContext, UserProfileContext } from '../App';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
//import EditSpecsBackdrop from './EditSpecsBackdrop';
import ShowSetups from './ShowSetups';
import SetupImport from "./SetupImport";
import PropTypes from 'prop-types';
import { ErrorMessage } from "../common/Constant";
import { SetupCategory, SetupLevel } from "../common/types";

SetupsDetail.propTypes = {
  setupCategory: PropTypes.number,
  defaultFrequency: PropTypes.number
};

export default function SetupsDetail({setupCategory,defaultFrequency}) {

  const authContext = useContext(AuthContext);
  const {userProfile} = useContext(UserProfileContext);

  const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plantSetup, setPlantSetup] = useState(null);
  //const [frequency] = useState(defaultFrequency);
  

  const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});

  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true)
      getUpdatedSetups(); 
  }, [])
  

  useEffect(() => {
    if(didMount){
      setLoading(true);
      getUpdatedSetups();
    }
  }, [userProfile]);

  useEffect(() => {
    if(didMount){
      setLoading(true);
      getUpdatedSetups();
    }
  }, [defaultFrequency]);

  const getUpdatedSetups = () => {
    if(userProfile != null && userProfile.defaultPlant != null ){
      if(setupCategory == SetupCategory.basicsetup){
        updateBasicSetups();
      }else if(setupCategory == SetupCategory.calculation && defaultFrequency != null){
        updateCalcSetups();
      }
    }else{
      console.log("SetupsDetail -> userProfile is missing defaultPlant");
    }
  }


  // const handleEditSpecs = (savedSetups) => {
  //   if(savedSetups != null){
  //     getUpdatedSetups();
  //     setConfirmation({open:true,message:"Plant setups was saved for " + savedSetups.plant.name});
  //   }
  // }


  const updateBasicSetups = () => {
    if(userProfile == null){
      console.log("updateSetups - userprofile is null");
      return;
    }
    fetch('api/setups/plantsetups/' + userProfile.defaultPlant.id ,
        {
            method: "GET",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            }
        })
        .then(response => {
          if(response.ok) {
            response.json().then(plantSetup => {
              setPlantSetup(plantSetup);
              setLoading(false);
            });
          }else if(response.status == 400){
            response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setRejection({open:true, message:"Internal error"});
      });
    }

  const updateCalcSetups = () => {
    if(userProfile == null){
      console.log("updateSetups - userprofile is null");
      return;
    }
    fetch('api/setups/plantsetups/byfrequency/' + userProfile.defaultPlant.id + '?Frequency=' + defaultFrequency,
        {
            method: "GET",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            }
        })
        .then(response => {
          if(response.ok) {
            response.json().then(plantSetup => {
              setPlantSetup(plantSetup);
              setLoading(false);
            });
          }else if(response.status == 400){
            response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setRejection({open:true, message:"Internal error"});
      });
    }

    const handleConfirmationClose = () => {
      setConfirmation({open:false,message:""});
    }

    const handleRejectionClose = () => {
      setRejection({open:false,message:""});
    }

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
      <Snackbar
        severity="success"
        open={confirmation.open}
        autoHideDuration={3000}
        onClose={handleConfirmationClose}
        message={confirmation.message}
      />
       <Snackbar
        severity="error"
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
        <Box sx={{ m: 0, width: "100%" }}>
           <ShowSetups frequency={defaultFrequency} setupDesc={plantSetup.plant} setupUpdateCallback={getUpdatedSetups} setupCategory={setupCategory} setupLevel={SetupLevel.plant}/>
            {plantSetup.turbines.map((setupDesc) => 
              (
                <ShowSetups key={setupDesc.id} frequency={defaultFrequency} setupDesc={setupDesc} setupUpdateCallback={getUpdatedSetups} setupCategory={setupCategory} setupLevel={SetupLevel.turbine}/>
                )
              )}
        </Box>
      <SetupImport setupCategory={setupCategory} setupImportCallback={getUpdatedSetups}/>
      </React.Fragment>
  );
  }