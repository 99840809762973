import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import { AuthContext } from "../App";

const Profile = () => {
  const { user, isLoading } = useAuth0();
  const authContext = useContext(AuthContext);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    authContext.isAuthenticated() && (
      <article>
        {user?.picture &&
        <img
          style={{ maxWidth: 50, maxHeight: 50 }}
          src={user.picture}
          alt={user?.name}
        />}
        <h2>{user?.name}</h2>
        <ul>
          {Object.keys(user).map((objKey,i) => <li key={i}>{objKey}: {user[objKey]}</li>)}
        </ul>
        {/* {userMetadata ? (
          <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
        ) : (
          "No user metadata defined"
        )} */}
        <br/>
        {/* <pre>{JSON.stringify(profile, null, 2)}</pre> */}
        React version: {React.version}<br/>
        </article>
    )
  );
};

export default Profile;