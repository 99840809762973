import React, { useState, useEffect, useContext } from "react";
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import { CardActions } from "@mui/material";
//import Typography from '@mui/material/Typography';
import {AuthContext, UserProfileContext } from '../App';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import EditSpecsBackdrop from './EditSpecsBackdrop';
import ShowSpecs from './ShowSpecs';
import { ErrorMessage } from "../common/Constant";

export default function SpecsDetail() {

  const authContext = useContext(AuthContext);
  const {userProfile} = useContext(UserProfileContext);

  const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plantSpec, setPlantSpec] = useState(null);
  
  const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});

  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true)
    getUpdatedSpecs(); 
  }, [])
  

  useEffect(() => {
    if(didMount){
      setLoading(true);
      getUpdatedSpecs();
    }
  }, [userProfile]);

  const getUpdatedSpecs = () => {
    if(userProfile != null && userProfile.defaultPlant != null){
      updateSpecs(userProfile.defaultPlant.id);
    }else{
      console.log("SpecsDetail -> userProfile is missing defaultPlant");
    }
  }


  const handleEditSpecs = (savedSpecs) => {
    if(savedSpecs != null){
      getUpdatedSpecs();
      setConfirmation({open:true,message:"Plant import specification was saved for " + savedSpecs.plant.name});
    }
  }

  const updateSpecs = (id) => {
    fetch('api/importspecs/plant/' + id,
        {
            method: "GET",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            }
        })
        .then(response => {
          if(response.ok) {
            response.json().then(plantSpec => {
              setPlantSpec(plantSpec);
              setLoading(false);
            });
          }else if(response.status == 400){
            response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setRejection({open:true, message:"Internal error"});
      });
    }

    const handleConfirmationClose = () => {
      setConfirmation({open:false,message:""});
    }

    const handleRejectionClose = () => {
      setRejection({open:false,message:""});
    }

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
      <Snackbar
        severity="success"
        open={confirmation.open}
        autoHideDuration={3000}
        onClose={handleConfirmationClose}
        message={confirmation.message}
      />
       <Snackbar
        severity="error"
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
    {/* <Card sx={{ m: 0, minWidth: 275 }}>
      <CardContent> */}
        <Box sx={{ m: 0, width: "100%" }}>
           <ShowSpecs specDesc={plantSpec.plant}  />
            {plantSpec.turbines.map((specDesc) => 
              (
                <ShowSpecs key={specDesc.id} specDesc={specDesc} />
                )
              )}
        </Box>
      {/* </CardContent>
      <CardActions disableSpacing sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}> */}
      {plantSpec != null && 
        <EditSpecsBackdrop editCallback={handleEditSpecs}/>
      }
      {/* </CardActions>
    </Card>
     */}
    </React.Fragment>
  );
  }