import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

ShowTurbinesTable.propTypes = {
  turbines: PropTypes.array
};

export default function ShowTurbinesTable({turbines}) {

  return (
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>Machine Name</TableCell>
          <TableCell align="right">Min flow&nbsp;(M3/s)</TableCell>
          <TableCell align="right">Max flow&nbsp;(M3/s)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {turbines.map((row) => (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.min}</TableCell>
            <TableCell align="right">{row.max}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
}