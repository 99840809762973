import React, {  useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import EditPlant from './EditPlant';
import EditTurbines from './EditTurbines';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import {UserProfileContext} from '../App';
import {AuthContext} from '../App';
import CircularProgress from '@mui/material/CircularProgress';
import { ErrorMessage } from '../common/Constant';


const steps = ['Plant info', 'Edit machines'];

EditPlantStepper.propTypes = {
  defaultPlant: PropTypes.object,
  editPlantCallback: PropTypes.func
  };
  
export default function EditPlantStepper({defaultPlant, editPlantCallback}) {

  const authContext = useContext(AuthContext);
  const {userProfile, setUserProfile} = useContext(UserProfileContext);

  const [plant, setPlant] = useState(defaultPlant);
  const [turbines, setTurbines] = useState(defaultPlant?.turbines);
  const [editMode, setEditMode] = useState(!defaultPlant.isNew);
  
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(!editMode);
  
  const [plantValid, setPlantValid] = useState(false);
  const [turbinesValid, setTurbinesValid] = useState(false);


  //const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});

  const [didMount, setDidMount] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true);
    setLoading(false);
  }, []);

  useEffect(() => {
    if(didMount){
      console.log("EditPlantStepper getUpdatedPlant()");
      getUpdatedPlant();
    }
  }, [userProfile]);

  const getUpdatedPlant = () => {
    if(userProfile != null && userProfile.defaultPlant != null){
      updatePlant(userProfile.defaultPlant.id);
    }else{
      console.log("PlantDetail -> userProfile is missing defaultPlant");
    }
  }

  const handleNext = () => {
    if(activeStep == 0){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(editMode){
          setNextDisabled(false);
        }else{
          setNextDisabled(!turbinesValid);
        }
    }
    else if(activeStep == 1){
      submitForm();
    }
  };

  const resetValues = ()=>{
    setPlant(defaultPlant);
    setTurbines(defaultPlant?.turbines);
    setEditMode(!defaultPlant.isNew);
    setActiveStep(0);
  }

  const updateValues = (savedPlant)=>{
    setPlant(savedPlant);
    setTurbines(savedPlant.turbines);
    setEditMode(!savedPlant.isNew);
    setActiveStep(0);
  }

  const handleCancel = () =>{
    resetValues();
    editPlantCallback(null);
  }

  const handleFinish = (savedPlant) =>{
    updateValues(savedPlant);
    handleEmptyDefaultPlant(savedPlant);
    editPlantCallback(savedPlant);
  }

  const handleEmptyDefaultPlant = (savedPlant) =>{
      if(userProfile.defaultCompany != null && userProfile.defaultPlant == null){
        var newProfile = userProfile;
        newProfile.defaultPlant = savedPlant;
        newProfile.defaultCompany.plants.push(savedPlant);
        setUserProfile(newProfile);
      }
  }

  useEffect(() => {
    if(didMount){
      setNextDisabled(!plantValid);
    }
  }, [plantValid]);

  useEffect(() => {
    if(didMount){
      setNextDisabled(!turbinesValid);
    }
  }, [turbinesValid]);

  useEffect(() => {
    if(didMount){
      // Update turbines on plant and 
      setPlant({
        ...plant,
        turbines: turbines
      });
    }
  }, [turbines]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if(editMode){
      setNextDisabled(false);
    }else{
      setNextDisabled(!plantValid);
    }
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  // const handleConfirmationClose = () => {
  //   setConfirmation({open:false,message:""});
  // }
  const handleRejectionClose = () => {
    setRejection({open:false,message:""});
    editPlantCallback(null); 
  }

  const submitForm = () => {
    fetch('api/plants/' + userProfile.defaultCompany.id,
        {
            method: "POST",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            },
            body: JSON.stringify(plant)
        })
        .then(response => {
          if(response.ok) {
            response.json().then(plant => {
              console.log("plant saved Ok: " + JSON.stringify(plant));
              handleFinish(plant);
            });
          }else if(response.status == 400){
              response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setRejection({open:true, message:"Internal error"});
      });
    }

    const updatePlant = (id) => {
      fetch('api/plants/plant/' + id,
          {
              method: "GET",
              cache: "no-cache",
              headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authContext.token}`
              }
          })
          .then(response => {
            if(response.ok) {
              response.json().then(plant => {
                setPlant(plant);
                setTurbines(plant.turbines);
                setLoading(false);
              });
            }else if(response.status == 400){
              response.json().then(error => {
                setRejection({open:true, message: ErrorMessage(error)});
              });
            }else{
              setRejection({open:true, message:"Server error"}); 
            }
          }).catch((err) => {
            console.log(err.message);
            setRejection({open:true, message:"Internal error"});
        });
      }

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
      <Snackbar
        severity="error"
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
   
    <Card sx={{ width: '90%', p: 3}} >
      <Stepper activeStep={activeStep} sx={{ pb: 2}}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={{ maxHeight: 500, overflow: 'scroll'}} >
      {activeStep == 0 ? (
          <EditPlant key={plant.id} plant={plant} setPlant={setPlant} setValid={setPlantValid}/>
      ) : (
          <EditTurbines key={plant.id} turbines={turbines} setTurbines={setTurbines} setValid={setTurbinesValid}/>
      )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button
          color="inherit"
          onClick={handleCancel}
          sx={{ mr: 1 }}
        >
          Cancel
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button disabled={nextDisabled} onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Card>
    </React.Fragment>
  );
}
