import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
  
import PropTypes from 'prop-types';

ShowMultipleBarChart.propTypes = {
  titleOptions: PropTypes.object,
  yTitleOptions: PropTypes.object,
  data : PropTypes.object,
  value: PropTypes.number,
  index: PropTypes.number, 
}

export default function ShowMultipleBarChart({titleOptions, yTitleOptions, data, value, index}) {

  ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const options = {
    responsive: true,
    plugins: {
        legend: {
        position: 'top',
        },
        title: titleOptions
    },
    scales: {
      x: {
        stacked: true,
        title: {
          dislay: true,
          title: 'Date'
        }
      },
      y: {
        stacked: true,
        title: yTitleOptions
      }
    }
    };

// const labels = values.labels;
// const data = {
// labels,
// datasets: [
//     {
//     label: chartOptions.label,
//     data: values.data,
//     borderColor: chartOptions.borderColor,
//     backgroundColor: chartOptions.backgroundColor,
//     },
// ],
// };

  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Bar options={options} data={data} />
      </Box>
    )}
  </div>
  );
}