import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import EditPlantStepper from './EditPlantStepper';
import PropTypes from 'prop-types';

EditPlantBackdrop.propTypes = {
  defaultPlant: PropTypes.object,
  editPlantCallback: PropTypes.func
}

export default function EditPlantBackdrop({defaultPlant, editPlantCallback}) {
  
  const [open] = React.useState(defaultPlant != null);
  
  const handleEditPlant = (plant) => {
    editPlantCallback(plant);
  };


  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <EditPlantStepper defaultPlant={defaultPlant} editPlantCallback={handleEditPlant}/>
      </Backdrop>
    </React.Fragment>
  );
}
