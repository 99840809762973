import React, {  useState, useEffect } from "react";
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import { CardActions } from "@mui/material";
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
//import Button from '@mui/material/Button';

import DeleteIcon from '@mui/icons-material/Delete';
import EditSpec from './EditSpec';
import Stack from '@mui/material/Stack';

import {SetupType} from '../common/types';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

EditSpecs.propTypes = {
  editSpecsCallback: PropTypes.func,
  specsDesc: PropTypes.object,
  setSpecs: PropTypes.func,
  setValid: PropTypes.func
};

export default function EditSpecs({specsDesc, setSpecs, setValid}) {

    //const [valid, setValid] = useState(true);
    const [values, setValues] = useState(specsDesc.specs);

    //let validationItems = specsDesc.specs.map((spec) => ({item:spec, valid:true}) );
    const [validation, setValidation] = useState(values.map((spec) => {
      return {item:spec, valid:true};
      }));
  
    const [didMount, setDidMount] = useState(false);
    
    // Setting didMount to true upon mounting
    useEffect(() => { 
      setDidMount(true);
    }, [])

    const setItemValid = (newItem, valid) => {
      const updatedValidation = validation.map((validationItem) => {
        if(validationItem.item.id == newItem.id) {
          return { item: newItem , valid: valid};
        }else {
          return validationItem;
        }
      });
      setValidation(updatedValidation);
    }
   
    const handleItemChange = (item) => {
      // TODO Test if we should use values or specsDesc.specs
      const updatedValues = specsDesc.specs.map((value) => {
        if(item.id == value.id) {
          return item;
        }else {
          return value;
        }
      })
      setValues(updatedValues);  
    };

    useEffect(() => {
      if(didMount){
        setSpecsIfValidated();
      }
    }, [validation]);

    const setSpecsIfValidated = () => {
      if(validation.every( (validationItem)  => {
         return validationItem != undefined && validationItem.valid == true; 
      } ))
      {
        // TODO: Maybe put in some validation that reservoir, price and inflow exist. 
        const specsToSave = values.filter((i) => i.x != '');

        setSpecs({
          ...specsDesc,
            specs: specsToSave
        });
        setValid(specsDesc,true);
      }else{
        setValid(specsDesc,false);
      }
    }
    
    const addValue = () => {
      const newItem = {
        id: uuidv4(),
        setupType : SetupType.reservoir,
        description: "",
        worksheet: 0,
        x: "A",
        y: "C",
        start: "2",
        end: null,
        isNew:true
      };
      setValues(values.concat(newItem));
      setValidation(validation.concat({item:newItem,valid:true}));
    };

    const deleteValue = (spec) => {
      const updatedValues = values.filter((item) => item.id !== spec.id);
      setValues(updatedValues); 
      const updateValidation = validation.filter((validationItem) => validationItem.item.id !== spec.id);
      setValidation(updateValidation);
    };

    // const handleCancel = () => {
    //   editSpecsCallback();
    // }

    // const handleSubmit = () => {
    //   editSpecsCallback();
    // }

    return (
      <React.Fragment>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            Import specification for: {specsDesc.name}
          </Typography>
            {/* <Box sx={{ p:1, maxHeight: 500, overflow: 'scroll'}} > */}
            <Box sx={{ p:1}} >
            {values.map((spec) => (
             <Typography key={spec.id} sx={{ mb: 1.5 }} variant="h5" component="div">
                <Stack direction="row">
                  <EditSpec spec={spec} setSpec={handleItemChange} setValid={setItemValid}/>
                  <IconButton aria-label="delete" onClick={() => deleteValue(spec)}>
                    <DeleteIcon/>
                  </IconButton>
                </Stack>
              </Typography>
            ))}
            </Box>
        {/* </CardContent>
        <CardActions sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}> */}
         <IconButton aria-label="add" onClick={addValue} sx={{display: "flex", width: '98%', justifyContent: "flex-end"}}>
              <AddIcon/>
          </IconButton>
        {/* </CardActions>
      </Card> */}
      </React.Fragment>
    );
}
