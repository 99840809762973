import React, {  useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from "@mui/material";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import {UserProfileContext} from '../App';
import {AuthContext} from '../App';
import CircularProgress from '@mui/material/CircularProgress';
import EditSpecs from './EditSpecs';
import { ErrorMessage } from '../common/Constant';
//import { SetupType } from '../common/Constant';

EditPlantSpecs.propTypes = {
  //defaultPlantSpec: PropTypes.object,
  editCallback: PropTypes.func
  };
  
export default function EditPlantSpecs({editCallback}) {

    
  const authContext = useContext(AuthContext);
  const {userProfile} = useContext(UserProfileContext);

  const [loading, setLoading] = useState(true);

  const [plantSpec, setPlantSpec] = useState(null);

  const [plantValid, setPlantValid] = useState(true);
  const [turbinesValid, setTurbinesValid] = useState(true);
  const [validation, setValidation] = useState(null);

  const [rejection, setRejection] = useState({open:false,message:""});

  const [didMount, setDidMount] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true);
    updateSpecs(userProfile.defaultPlant.id);
  }, []);

  useEffect(() => {
    if(didMount){
      //getUpdatedPlantSpecs();
    }
  }, [userProfile]);

  useEffect(() => {
    if(didMount){

      if(validation == null && plantSpec != undefined && plantSpec.turbines != undefined){
        setValidation(plantSpec.turbines.map((specsDesc) => {
         return {item:specsDesc, valid:true};
        }));
      }
    }
  }, [plantSpec]);

  useEffect(() => {
    if(didMount){
      if(validation.every( (validationItem)  => validationItem.valid == true ))
      {
        setTurbinesValid(true);
      }else{
        setTurbinesValid(false);
      }
    }
  }, [validation]);
  
  //TODO. maybe have a callback to delete items from validation when deleting an spec. 

  // const getUpdatedPlantSpecs = () => {
  //   if(userProfile != null && userProfile.defaultPlant != null){
  //     updateSpecs(userProfile.defaultPlant.id);
  //   }else{
  //     console.log("EditPlantSpecs -> userProfile is missing defaultPlant");
  //   }
  // }

  const handleCancel = () =>{
    editCallback(null);
  }

  const handleFinish = (savedSpecs) =>{
    editCallback(savedSpecs);
  }

  // const handleEmptyDefaultPlant = (savedPlant) =>{
  //     if(userProfile.defaultCompany != null && userProfile.defaultPlantSpec == null){
  //       var newProfile = userProfile;
  //       newProfile.defaultPlantSpec = savedPlant;
  //       newProfile.defaultCompany.plants.push(savedPlant);
  //       setUserProfile(newProfile);
  //     }
  // }


  const setPlant = (plant) =>{
    setPlantSpec({
      ...plantSpec,
      plant: plant
    });
  };

  const handleItemChange = (item) => {
    const updatedValues = plantSpec.turbines.map((value) => {
      if(value.id === item.id) {
        return item;
      }else {
        return value;
      }
    })
    setPlantSpec({
      ...plantSpec,
      turbines: updatedValues
    });
    //setTurbines(updatedValues);  
  };

  const plantValidationChanged = (spec, valid) => {
    setPlantValid(valid);
  } 

  const turbinesValidationChanged = (newItem, valid) =>{

    const updatedValidation = validation.map((validationItem) => {
      if(validationItem.item.id == newItem.id) {
        return { item: newItem , valid: valid};
      }else {
        return validationItem;
      }
    });
    setValidation(updatedValidation);

  }
  
  const handleRejectionClose = () => {
    setRejection({open:false,message:""});
  }

  const submitForm = () => {
    fetch('api/importspecs/plant' ,
        {
            method: "POST",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            },
            body: JSON.stringify(plantSpec)
        })
        .then(response => {
          if(response.ok) {
            handleFinish(plantSpec);
          }else if(response.status == 400){
            response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setRejection({open:true, message:"Internal error"});
      });
    }

    const updateSpecs = (id) => {
      fetch('api/importspecs/plant/' + id,
          {
              method: "GET",
              cache: "no-cache",
              headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authContext.token}`
              }
          })
          .then(response => {
            if(response.ok) {
              response.json().then(plantSpecs => {
                setPlantSpec(plantSpecs);
                setLoading(false);
              });
            }else{
              response.json().then(error => {
                console.log(error.code + ' ' + error.message);
                if(error.message != undefined){
                  setRejection({open:true, message: error.message});
                }else{
                  setRejection({open:true, message: "Got server error while saving plant"});
                }
              });
            }
          }).catch((err) => {
            console.log(err.message);
            setRejection({open:true, message:"Internal error"});
        });
      }

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
      <Snackbar
        severity="error"
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
    <Card sx={{ minWidth: 275}}>
        <CardContent>
            <Box sx={{ p:1, maxHeight: 500, overflow: 'scroll'}} >
            <EditSpecs key={plantSpec.id} specsDesc={plantSpec.plant} setSpecs={setPlant} setValid={plantValidationChanged} />
            {plantSpec.turbines.map((specDesc) => 
              (
                <EditSpecs key={specDesc.id} specsDesc={specDesc} setSpecs={handleItemChange} setValid={turbinesValidationChanged}/>
                )
              )}
            </Box>
        </CardContent>
        
        
        <CardActions sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
          <Button
            color="inherit"
            onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={!(plantValid && turbinesValid)}  onClick={submitForm}>
            Save
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
}
