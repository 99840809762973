import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import SetupBackdrop from './SetupBackdrop';
import SetupDelete from './SetupDelete';
import {formatDateTime} from '../common/Constant';

SetupList.propTypes = {
  setups : PropTypes.array,
  setupUpdateCallback: PropTypes.func
}

export default function SetupList({setups,setupUpdateCallback}) {

  //const [selectedId] = React.useState(null);

  return (
    setups &&
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Divider/>
      <List component="nav" aria-label="list of setups">
        { setups.map((setup) => 
        (
          <ListItemButton
            key={setup.id}
            // selected={setup.id == {selectedId}}
            >
            <ListItemText primary={setup.description} secondary={formatDateTime(setup)} />
            <SetupBackdrop setup={setup}/>
            <SetupDelete setup={setup} setupDeleteCallback={setupUpdateCallback} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}