import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import EditCompanyStepper from './EditCompanyStepper';
import PropTypes from 'prop-types';
// import EditIcon from '@mui/icons-material/Edit';
// import IconButton from '@mui/material/IconButton';

EditCompanyBackdrop.propTypes = {
  defaultCompany: PropTypes.object,
  editCompanyCallback: PropTypes.func
}

export default function EditCompanyBackdrop({defaultCompany, editCompanyCallback}) {
  const [open] = React.useState(defaultCompany != null);
  
  const handleEditCompany = (company) => {
    editCompanyCallback(company);
  };

  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  return (
    <React.Fragment>
      {/* <IconButton aria-label="edit" onClick={() => handleToggle()}>
        <EditIcon/>
      </IconButton> */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <EditCompanyStepper defaultCompany={defaultCompany} editCompanyCallback={handleEditCompany}/>
      </Backdrop>
    </React.Fragment>
  );
}
