import React, { useState, useEffect, useContext } from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import Close from '@mui/icons-material/Close';
import {AuthContext } from '../App';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import ValueXYLineChartBox from '../chart/ValueXYLineChartBox';
import DateValueLineChartBox from '../chart/DateValueLineChartBox';
import PropTypes from 'prop-types';
import moment from 'moment'
import {ErrorMessage} from '../common/Constant';
import {SetupType,SetupTypePrint} from '../common/types';

SetupCard.propTypes = {
  setup : PropTypes.object
}

export default function SetupCard({setup}) {

  const authContext = useContext(AuthContext);
  //const {userProfile} = useContext(UserProfileContext);

  const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(null);
  const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});

  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true)
    getSetup(); 
  }, [])
  

  useEffect(() => {
    if(didMount){
      //
    }
  }, [setup]);

const isValueXY = () => {
  if( setup.setupType == SetupType.reservoir || 
      setup.setupType == SetupType.turbinepower || 
      setup.setupType == SetupType.generatorpower){
        return true;
      }else{
        return false;
      }
}

  const route = () => {
    if(isValueXY()){
      return "valuexy/";
    }else{
      return "datevalue/";
    }
  }


  const getSetup = () => {
    fetch('api/setups/' + route() + setup.id,
        {
            method: "GET",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            }
        })
        .then(response => {
          if(response.ok) {
            response.json().then(values => {
              setValues(values);
              setLoading(false);
            });
          }else if(response.status == 400){
            response.json().then(error => {
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setRejection({open:true, message:"Internal error"});
      });
    }

  

  const handleConfirmationClose = () => {
    setConfirmation({open:false,message:""});
  }

  const handleRejectionClose = () => {
    setRejection({open:false,message:""});
  }

  const formatDateTime = (setup) => {
    return moment(setup.created).format("DD/MM/YYYY HH:mm:ss");
   }

  const title = () => {
    return SetupTypePrint(setup.setupType);
  }

  let shortTitle = title().substring(0, 1);

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
      <Snackbar
          severity="success"
          open={confirmation.open}
          autoHideDuration={3000}
          onClose={handleConfirmationClose}
          message={confirmation.message}
        />
        <Snackbar
          severity="error"
          open={rejection.open}
          autoHideDuration={3000}
          onClose={handleRejectionClose}
          message= {rejection.message}
        />
    <Card sx={{ width: '90%' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="Setup">
            {shortTitle}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <Close />
          </IconButton>
        }
        title={setup.description}
        subheader={formatDateTime(setup.created)}
      />
      <CardContent>
          
      { isValueXY() 
        ?
          <ValueXYLineChartBox key={setup.id} title="Setup" showTitle={false} label={title()} values={values}/>
        :
          <DateValueLineChartBox key={setup.id} title="Setup" showTitle={false} label={title()} values={values}/>
        }
      </CardContent>
      
    </Card>
    </React.Fragment>
  );
}