import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
//import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import {Item} from '../common/Constant';
import { SetupTypePrint } from "../common/types";

ShowSpec.propTypes = {
  spec: PropTypes.object
};

export default function ShowSpec({spec}) {

  //console.log(JSON.stringify(spec.desc));

  return (
    <React.Fragment>
       <Card sx={{ m: 0, p: 0, minWidth: 275 }}>
        <CardContent sx={{ padding: 0,
          "&:last-child": {
            paddingBottom: 0
          }}} >
       <Grid container spacing={2}>
        <Grid item xs={6} md={1.7} >
          <Item  sx={{ boxShadow: 0}}>{SetupTypePrint(spec.setupType)}</Item>
        </Grid>
        <Grid item xs={6} md={1.7}>
          <Item sx={{ boxShadow: 0}}>Description: {spec.description}</Item>
        </Grid>
        <Grid item xs={6} md={1.7}>
        <Item sx={{ boxShadow: 0}}>Worksheet: {Number(spec.worksheet) + 1}</Item>
        </Grid>
        <Grid item xs={6} md={1.7}>
          <Item sx={{ boxShadow: 0}}>X: {spec.x}</Item>
        </Grid>
        <Grid item xs={6} md={1.7}>
          <Item sx={{ boxShadow: 0}}>Y: {spec.y}</Item>
        </Grid>
        <Grid item xs={6} md={1.7}>
           <Item sx={{ boxShadow: 0}}>Start: {spec.start}</Item>
        </Grid>
        <Grid item xs={6} md={1.7}>
           <Item sx={{ boxShadow: 0}}>End: {spec.end}</Item>
        </Grid>
      </Grid> 
      </CardContent>
      </Card>
     </React.Fragment>
  );
}

