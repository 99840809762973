import React, {  useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import EditAdminUser from "./EditAdminUser";
import { v4 as uuidv4 } from 'uuid';

EditAdminUsers.propTypes = {
  admins: PropTypes.array,
  setAdmins: PropTypes.func,
  setValid: PropTypes.func
};

export default function EditAdminUsers({admins, setAdmins, setValid}) {

    const [values, setValues] = useState(admins);
    const [validation, setValidation] = useState(admins.map((admin) =>
    {
      return {item:admin, valid:true};
    }
    ));

    const [didMount, setDidMount] = useState(false);
    
    // Setting didMount to true upon mounting
    useEffect(() => { setDidMount(true) }, [])

    const setItemValid = (newItem, valid) => {
      const updatedValidation = validation.map((validationItem) => {
        if(validationItem.item.id == newItem.id) {
          return { item: newItem , valid: valid};
        }else {
          return validationItem;
        }
      });
      setValidation(updatedValidation);
    }
    const handleItemChange = (item) => {
      const updatedValues = values.map((value) => {
        if(item.id == value.id) {
          return item;
        }else {
          return value;
        }
      })
      setValues(updatedValues);  
    };

    useEffect(() => {
      if(didMount){
        setAdminsIfValidated();
      }
    }, [validation]);

    const setAdminsIfValidated = () => {
      if(validation.every( (validationItem)  => {
        return validationItem != undefined && validationItem.valid == true; 
        }))
      {
        const adminsToSave = values.filter((i) => i.email != '');
        setAdmins(adminsToSave);
        setValid(true);
      }else{
        setValid(false);
      }
    }

    const addValue = () => {
      const newItem = {id: uuidv4() , email:'', userType: 'admin', isNew:true};
      setValues(values.concat(newItem));
      setValidation(validation.concat({item:newItem,valid:false}));
    };

    // const deleteValue = (i) => {
    //   const updatedValues = values;
    //   updatedValues.splice(i, 1); 
    //   setValues(updatedValues);

    //   const updateValidation = validation;
    //   updateValidation.splice(i, 1); 
    //   setValidation(updateValidation); 
    // };

    const deleteValue = (admin) => {
      const updatedValues = values.filter((item) => item.id !== admin.id);
      setValues(updatedValues); 
      const updateValidation = validation.filter((validationItem) => validationItem.item.id !== admin.id);
      setValidation(updateValidation);
    };

    return (
      <div>
      <Card >
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Admin users
          </Typography>
          
            {values.map((admin) => (
              <Typography key={admin.id} sx={{ mb: 1.5 }} variant="h5" component="div">
                <Stack direction="row">
                  <EditAdminUser adminUser={admin} setAdminUser={handleItemChange} setValid={setItemValid}/>
                  <IconButton aria-label="delete" onClick={() => deleteValue(admin)}>
                  <DeleteIcon/>
                </IconButton>
              </Stack>
              </Typography>
            ))}
            <IconButton aria-label="add" onClick={addValue}>
              <AddIcon />
            </IconButton>
        </CardContent>
      </Card>
      </div>
    );
}
