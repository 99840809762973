import React, { useState ,useEffect, useContext} from "react";
import {AuthContext, UserProfileContext } from '../App';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import SetupsDetail from "../setup/SetupsDetail";
import { SetupCategory, Frequency } from '../common/types';
import { ErrorMessage } from '../common/Constant';
import EditCalculationSetup from "./EditCalculationSetup";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CalculationTabs  from './CalculationTabs';
import dayjs from 'dayjs';
import moment from "moment";
import { Alert } from "@mui/material";

export default function AdHocCalculation() {

  const authContext = useContext(AuthContext);
  const {userProfile} = useContext(UserProfileContext);

  const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [calculating, setCalculating] = useState(false);
  const [setupsValidation, setSetupsValidation] = useState({valid:false, errors:[]});
  const [calculationSetup, setCalculationSetup] = useState({frequency: Frequency.Weekly, startDate: dayjs('2014-08-18T00:00:00').toDate, endDate: dayjs('2014-08-18T23:00:00').toDate, startHeight:680, endHeight:680});
  const [frequency, setFrequency] = useState(calculationSetup.frequency);
  const [calculationSetupUpstream, setCalculationSetupUpstream] = useState({frequency: Frequency.Weekly, startDate: dayjs('2014-08-18T00:00:00').toDate, endDate: dayjs('2014-08-18T23:00:00').toDate, startHeight:680, endHeight:680});
  //const [calculationSetups, setCalculationSetups] = useState(null);
  
  const [calculationSetupBoundaries, setCalculationSetupBoundaries] = useState(null);
  const [calculationSetupValid, setCalculationSetupValid] = useState(true);
  const [calculationResult,setCalculationResult] = useState(null);
  //const [excelBytes,setExcelBytes] = useState(null);
  const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});


  // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true);
    updateSetupsValidation(calculationSetup.frequency);
  }, [])
  
  useEffect(() => {
    if(didMount && calculationSetupUpstream != null && calculationSetup.frequency != calculationSetupUpstream.frequency){
      setFrequency(calculationSetupUpstream.frequency)
      updateSetupsValidation(calculationSetupUpstream.frequency);
      setCalculationResult(null);
    }
  }, [calculationSetupUpstream]);

  useEffect(() => {
    if(didMount){
      setLoading(true);
      updateSetupsValidation(calculationSetup.frequency);
      setCalculationResult(null); // When we change plant, calculationResult needs to be reset.
    }
  }, [userProfile]);

  useEffect(() => {
    if(didMount){
      if(setupsValidation.valid){
        setLoading(true);
        updateCalculationSetupDefaults();
      }else{
        setLoading(false);
      }
    }
  }, [setupsValidation]);

  const updateSetupsValidation = (frequency) => {
    if(userProfile == null){
      console.log("updateSetupsValidation - userprofile is null");
      return;
    }

    if(userProfile.defaultPlant == null){
      console.log("defaultPlant is null");
      setLoading(false);
      setRejection({open:true, message: "Please setup a plant and import all necessary setups before calculating."});
      return;
    }

    fetch('api/setups/plantsetups/validation/' + userProfile.defaultPlant.id + '?Frequency=' + frequency,
        {
            method: "GET",
            cache: "no-cache",
            headers:{
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authContext.token}`
            }
        })
        .then(response => {
          if(response.ok) {
            response.json().then(validation => {
              setLoading(false);
              setSetupsValidation(validation);
            });
          }else if(response.status == 400){
            response.json().then(error => {
              setLoading(false);
              setRejection({open:true, message: ErrorMessage(error)});
            });
          }else{
            setLoading(false);
            setRejection({open:true, message:"Server error"}); 
          }
        }).catch((err) => {
          console.log(err.message);
          setLoading(false);
          setRejection({open:true, message:"Internal error"});
        });
    }

    const parseFileNameFromContentDisposition = ( contentDisposition ) => {
      const filenamePattern = new RegExp("HPC_Calcresult_.*xlsx;");
      let matches = contentDisposition.match(filenamePattern);
      return matches[0].replace(';','');
    }

    const updateCalculationSetupDefaults = () => {
      if(userProfile == null){
        console.log("updateCalculationSetupDefaults - userprofile is null");
        return;
      }
  
      fetch('api/setups/plantsetups/defaults/' + userProfile.defaultPlant.id + '?Frequency=' + frequency,
          {
              method: "GET",
              cache: "no-cache",
              headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authContext.token}`
              }
          })
          .then(response => {
            if(response.ok) {
              response.json().then(defaults => {
                setCalculationSetupBoundaries(defaults.calculationSetupBoundaries);
                setCalculationSetup(defaults.calculationUserSetup);
                setCalculationSetupUpstream(defaults.calculationUserSetup);
                setLoading(false);
              });
            }else if(response.status == 400){
              response.json().then(error => {
                setRejection({open:true, message: ErrorMessage(error)});
                setLoading(false);
              });
            }else{
              setRejection({open:true, message:"Server error"}); 
              setLoading(false);
            }
          }).catch((err) => {
            console.log(err.message);
            setRejection({open:true, message:"Internal error"});
            setLoading(false);
        });
      }
  
    const submitCalculation = () => {
      if(userProfile == null){
        console.log("submitCalculation - userprofile is null");
        return;
      }
      setCalculationResult(null);
      setCalculating(true);

      //const url = 'api/Calculator/calculateSequential/' + userProfile.defaultPlant.id;
      const url = 'api/Calculator/adhoc/' + userProfile.defaultPlant.id;
      fetch(url,
          {
              method: "POST",
              cache: "no-cache",
              headers:{
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authContext.token}`
              },
              body: JSON.stringify(calculationSetupUpstream)
          })
          .then(response => {
            if(response.ok) {
              response.json().then(calculationResult => {
                setCalculationResult(calculationResult);
              });
            }
            else if(response.status == 400){
              response.json().then(error => {
                setRejection({open:true, message: ErrorMessage(error)});
              });
            }else{
              setRejection({open:true, message:"Server error"}); 
            }
            setCalculating(false);
          }).catch(() => {
            setRejection({open:true, message:"Internal error"});  
        });
      }

      const exportCalculationAsExcel = () => {
        if(userProfile == null){
          console.log("exportCalculation - userprofile is null");
          return;
        }
        
        const url = 'api/Export/calculation/excel/' + userProfile.defaultPlant.id;
        fetch(url,
            {
                method: "POST",
                cache: "no-cache",
                headers:{
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${authContext.token}`
                },
                body: JSON.stringify(calculationResult.optimalRoute)
            })
            .then(response => {
              if(response.ok) {
                //response.headers.forEach(console.log);
                const contentDisposition = response.headers.get('content-disposition'); 
                let filename = parseFileNameFromContentDisposition(contentDisposition);
                
                response.blob().then( blob => {
                  downloadExcelFile(blob, filename);
                });
                
              }else if(response.status == 400){
                response.json().then(error => {
                  setRejection({open:true, message: ErrorMessage(error)});
                });
              }else{
                setRejection({open:true, message:"Server error"}); 
              }
            }).catch((err) => {
              console.log(err.message);
              setRejection({open:true, message:"Internal error"});
          });
        }
  
        
    const getSetupsValidationErrorMessages = () => {
      if(setupsValidation.errors?.length > 0){
               return <Typography sx={{  m: 2, fontSize: 16 }} color="error.main" gutterBottom>
                        Please upload necessary basic and calculation setups to be able to calculate
                     </Typography>
       }
       return "";
     }

    const isValid = () =>{
      return calculationSetupValid && setupsValidation.valid;
    }

    const handleConfirmationClose = () => {
      setConfirmation({open:false,message:""});
    }

    const handleRejectionClose = () => {
      setRejection({open:false,message:""});
    }

    const downloadResultFile = () => {
    
        const file = new Blob([JSON.stringify(calculationResult)], {type: 'application/json'});
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        let fileName = "HPC-calculation-" + moment().format('YYYY-MM-DD_HHmm') + ".json";
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const downloadExcelFile = (file , filename ) => {
            if(file == null) return;
          
            const url = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            //let fileName = "HPC-calculation-" + moment().format('YYYY-MM-DD_HHmm') + ".xlsx";
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
      }


  return (
    loading ? 
      <Box sx={{ display: 'flex' }}>
            <CircularProgress />
      </Box>
      :
    <React.Fragment>
      <Snackbar
        open={confirmation.open}
        autoHideDuration={3000}
        onClose={handleConfirmationClose}
        message={confirmation.message}
      />
       <Snackbar
        open={rejection.open}
        onClose={handleRejectionClose}
      >
        <Alert
          onClose={handleRejectionClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {rejection.message}
        </Alert>
      </Snackbar>
        {userProfile.defaultPlant == null ? 
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          This company has no plants
          </Typography>
          :
          (<Box sx={{ m: 0, width: "100%" }}>
          {/* {calculationResult == null && */}
          <SetupsDetail setupCategory={SetupCategory.calculation} defaultFrequency={frequency}/>      
          {/* } */}
          <EditCalculationSetup defaultValues={calculationSetup} boundaries={calculationSetupBoundaries} setValue={setCalculationSetupUpstream} setValid={setCalculationSetupValid}/> 
          {isValid() ?
            <React.Fragment>
              <Button aria-label="edit" onClick={() => submitCalculation()} sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
                  Calculate
              </Button>
            </React.Fragment>
          : 
          <React.Fragment>
            {getSetupsValidationErrorMessages()}
          </React.Fragment>
           }

          { calculating ?
            <Box sx={{ display: 'flex' }}>
            <CircularProgress />
            </Box> :
            // <ShowCalculation calculationResult={calculationResult}/> 
            <React.Fragment>
              {calculationResult != null &&
              <React.Fragment>
                <h4>TotalSeconds: {calculationResult.statistics.calculationTotalSeconds}</h4>
                <CalculationTabs calculationResult={calculationResult}/>
                <Button aria-label="edit" onClick={() => downloadResultFile()} sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
                  Download Json result
                </Button>
                <Button aria-label="edit" onClick={() => exportCalculationAsExcel()} sx={{display: "flex", width: '96%', justifyContent: "flex-end"}}>
                  Download Excel result
                </Button>
              </React.Fragment>
              }
            </React.Fragment>
           }
          </Box> )
          }
      </React.Fragment>
  );
  }