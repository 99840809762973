import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

EditAdminUser.propTypes = {
  adminUser: PropTypes.object,
  setAdminUser: PropTypes.func,
  setValid: PropTypes.func
};

export default function EditAdminUser({adminUser, setAdminUser, setValid}) {
  

  const defaultValidation = {
    email: true
  };


  const helperTexts = {
    email: "aaa@gmail.com"
  };

  const [values, setValues] = useState(adminUser);
  const [validation, setValidation] = useState(defaultValidation);
  
  const [didMount, setDidMount] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValues({
      ...values,
      [name]: value,
      });

  };
 
  const emailRegex = RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");

  useEffect(() => {
    if(didMount){
      const emailValid = emailRegex.test(values.email);
      setValidation({
        ...validation,
          email: emailValid
      });
    }
  }, [values.email]);

  useEffect(() => {
    if(didMount){
      setAdminUserIfValidated();
    }
  }, [validation]);

  const setAdminUserIfValidated = () =>{
    if(values.email != '' && validation.email){
      setAdminUser(values);
      setValid(values,true);
    }else{
      setValid(values,false);
    }
  }

  return (
    <div>
    <Card >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Enter details for admin user
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
            <TextField required id="email-input" name="email" label="Email" variant="outlined" value={values.email} onChange={handleInputChange} error={!validation.email} helperText={helperTexts.email}/>
        </Typography>
      </CardContent>
    </Card>
    </div>
  );
}

