import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ShowSpec from './ShowSpec';

//import {SetupType} from '../common/types';
import Box from '@mui/material/Box';

import PropTypes from 'prop-types';

ShowSpecs.propTypes = {
  specDesc: PropTypes.object
};

export default function ShowSpecs({specDesc}) {
  
    return (
      <React.Fragment>
        <Card sx={{ m: 2, minWidth: 275 }}>
        <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            Import specification for: {specDesc.name}
          </Typography>
            <Box sx={{ p:1}} >
            {specDesc.specs.map((spec, index) => (
              <Typography key={index} sx={{ mb: 1.5 }} variant="h5" component="div">
                  <ShowSpec spec={spec} />
              </Typography>
            ))}
            </Box>
            </CardContent>
            </Card>
      </React.Fragment>
    );
}
