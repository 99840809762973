import React, {useState, useContext, useEffect}  from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AuthContext } from '../App';
import { UserProfileContext } from '../App';
import Snackbar from '@mui/material/Snackbar';
import EditPlantBackdrop from '../plant/EditPlantBackdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DeletePlant from './DeletePlant';
import {StepType} from '../common/types';
import {ErrorMessage} from '../common/Constant';
import { v4 as uuidv4 } from 'uuid';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
//import DemoPlantBackdrop from './DemoPlantBackdrop';

  export default function PlantsTable () {

  const authContext = useContext(AuthContext);
  const {userProfile,setUserProfile} = useContext(UserProfileContext);
  const company = userProfile?.defaultCompany;
  
  const [loading, setLoading] = useState(true);
  
  const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});

  const [plants, setPlants] = useState([]);
  const [editPlant, setEditPlant] = useState(null);
  //const [demoPlant, setDemoPlant] = useState(false);

  const [didMount, setDidMount] = useState(false);

  const getEmptyPlant = () => {
    return { 
      id: uuidv4(), 
      name:"", 
      dropLossCoefficient: "",
      centerTurbineMoh: "",
      step: "",
      stepType: StepType.Step , 
      turbines: [ {id: uuidv4(), name:'', min:'', max:'', priority:0, isNew: true}],
      isNew: true
    }
  };


  const populatePlantsData = () => {
    if(company == undefined){
      setPlants([]);
      return;
    }
    
    fetch('api/plants/' + company.id , 
    {
      headers: { Authorization: `Bearer ${authContext.token}` }
    })
    .then(response => {
        if(response.ok) {
          response.json().then(plants => {
            setPlants(plants);
          });
        }else if(response.status == 400){
          response.json().then(error => {
            setRejection({open:true, message: ErrorMessage(error)});
          });
        }else{
          setRejection({open:true, message:"Server error"}); 
        }
      }).catch((err) => {
        console.log(err.message);
        setRejection({open:true, message:"Internal error"});
        setPlants([]);
    });
  }

    // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true) 
    populatePlantsData();
  }, [])

  useEffect(() => { 
    if(didMount){
      //setLoading(true);
      populatePlantsData();
    }
  }, [userProfile])

  useEffect(() => { 
    if(didMount){
      setLoading(false);
    }
  }, [plants])

  const handleConfirmationClose = () => {
    setConfirmation({open:false,message:""});
  }

  const reloadUserProfile = () => {
    setUserProfile(null);
  };

  const handleEditPlant = (plant) => {
    if(plant != null){
      reloadUserProfile();
      populatePlantsData();
      if(plant.isNew){
        setConfirmation({open:true,message:"plant was added: " + plant.name});
      }else{
        setConfirmation({open:true,message:"plant was saved: " + plant.name});
      }
    }
    //setDemoPlant(false);
    setEditPlant(null);
  }

  const handleDeletePlant = (plant) => {
    reloadUserProfile();
    populatePlantsData();
    setConfirmation({open:true,message:"plant was deleted: " + plant.name});
  }

  const handleRejectionClose = () => {
    setRejection({open:false,message:""});
  }
  
  const getFunctionKey = (mode, id) => {
    return mode + id;
  }

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
    <React.Fragment>
      {/* <AlertSuccess success={confirmation}/>  */}
      <Snackbar
        severity="success"
        open={confirmation.open}
        autoHideDuration={3000}
        onClose={handleConfirmationClose}
        message={confirmation.message}
      />
      <Snackbar
        severity="error"
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
      <Card sx={{ margin: 2 }}>
      <CardContent>
          {/* {demoPlant &&
            <DemoPlantBackdrop defaultOpen={demoPlant} editPlantCallback={handleEditPlant} />
          } */}
          {/* <Button onClick={() => setDemoPlant(true)}>Add demo Plant</Button> */}
          {editPlant != null &&
            <EditPlantBackdrop defaultPlant={editPlant} editPlantCallback={handleEditPlant} />
          }
          <Button onClick={() => setEditPlant(getEmptyPlant())}>Add Plant</Button>
          {
          plants.length == 0 ?
            <div/>
          :
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} size="small" aria-label="plants">
                <TableHead>
                  <TableRow>
                    <TableCell>Plant name</TableCell>
                    <TableCell align="right">Drop loss coefficient</TableCell>
                    <TableCell align="right">Center turbine MOH</TableCell>
                    <TableCell align="right">Machine steps</TableCell>
                    {/* <TableCell align="right">StepType</TableCell> */}
                    <TableCell>Functions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {plants.map((plant) => 
                  (
                    // <TableRow key={plant.id} onClick={() => showDetails(plant)}>
                    <TableRow key={plant.id}>
                      <TableCell component="th" scope="row">{plant.name}</TableCell>
                      <TableCell align="right">{plant.dropLossCoefficient}</TableCell>
                      <TableCell align="right">{plant.centerTurbineMoh}</TableCell>
                      <TableCell align="right">{plant.step}</TableCell>
                      {/* <TableCell align="right">{StepTypePrint(plant.stepType)}</TableCell> */}
                      <TableCell>
                      <IconButton aria-label="edit" onClick={() => setEditPlant(plant)} >
                        <EditIcon/>
                      </IconButton>
                      <DeletePlant key={getFunctionKey("delete", plant.id)} defaultPlant={plant} deletePlantCallback={handleDeletePlant}/> 
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            }
          </CardContent>
        </Card>   
    </React.Fragment>
  );
}