import React, {useState, useEffect}  from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
//import PropTypes from 'prop-types';
//import dayjs, { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
//import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Frequency, FrequencyPrint} from '../common/types';

import PropTypes from 'prop-types';
import { FormControl, Grid, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import 'dayjs/locale/nb';

//import { DateRange } from '@mui/x-date-pickers-pro/models/range';
//import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

EditCalculationSetup.propTypes = {
    defaultValues: PropTypes.object,
    boundaries: PropTypes.object,
    setValue: PropTypes.func,
    setValid: PropTypes.func
  }

export default function EditCalculationSetup({defaultValues, boundaries, setValue, setValid }) {

  const defaultValidation = {
    frequency: true,
    startDate: true,
    endDate: true,
    startHeight: true,
    endHeight: true,
    startHeightExact: true,
    endHeightExact: true,
    energyEquivalent: true,
    hrvWaterValue: true,
    lrvWaterValue: true
  };

  const helperTexts = {
    frequency: "Calculation frequency",
    startDate: "Start date",
    endDate: "End date",
    startHeight: "Number e.g. 300 m",
    endHeight: "Number e.g 250 m",
    energyEquivalent: "Energy equivalent (kWh/m3)",
    hrvWaterValue: "Hrv Water value (NOK/MWh)",
    lrvWaterValue: "Lrv Water value (NOK/MWh)"
  };

  const [values, setValues] = useState(defaultValues);
  const [validation,setValidation] = useState(defaultValidation);
  const [didMount, setDidMount] = useState(false);
  const [endHeightOption, setEndHeightOption] = useState(true);
  const [tempEndHeight, setTempEndHeight] = useState(defaultValues.endHeight);
  const [isWeeklyOrDaily, setIsWeeklyOrDaily] = useState(defaultValues.frequency==Frequency.Weekly || defaultValues.frequency==Frequency.Daily);

  // const [range, setRange] = React.useState<DateRange<Dayjs>>([
  //   values.startDate,
  //   values.endDate,
  // ]);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])


  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValues({
      ...values,
      [name]: value,
      });
  };

  const energyEquivalentRegex = new RegExp("^[]?([0-9]{1,5}([.][0-9]*)?)$");
  const hrvWaterValueRegex = new RegExp("^[]?([0-9]{1,5}([.][0-9]*)?)$");
  const lrvWaterValueRegex = new RegExp("^[]?([0-9]{1,5}([.][0-9]*)?)$");
  
  
  const handleToggleChange = () => {
    setEndHeightOption(!endHeightOption);
    if(endHeightOption){
      setTempEndHeight(values.endHeight);
      setValues({
      ...values,
      endHeight: undefined
      });
    }
    else{
      setValues({
        ...values,
        endHeight: tempEndHeight
        });
    }
  };

  const handleStartDateChange = (value) => {
    setValues({
      ...values,
      startDate: value,
      });
  };

  const handleEndDateChange = (value) => {
    setValues({
      ...values,
      endDate: value,
      });
  };

  //  const startHeightRegex = new RegExp("^([0-9]{1,20})$");
  //  const endHeightRegex = new RegExp("^([0-9]{1,20})$");
   

   useEffect(() => {
    if(didMount){
      const endDate = dayjs(values.endDate);
      const minDate = dayjs(boundaries.minDate);  
      
      const startDateIsLargerThanMinDate = dayjs(values.startDate) >= minDate;
      const startDateIsBeforeEndDate = dayjs(values.startDate) < endDate;
      const startDateValid = startDateIsLargerThanMinDate && startDateIsBeforeEndDate;
      setValidation({
        ...validation,
        startDate: startDateValid
      });
    }
  }, [values.startDate]);

  useEffect(() => {
    if(didMount){
      const startDate = dayjs(values.startDate);
      const maxDate = dayjs(boundaries.maxDate);  
      
      const endDateIsSmallerThanMaxDate = values.endDate <= maxDate; 
      const endDateIsAfterStartDate = values.endDate > startDate; 
      const endDateValid = endDateIsSmallerThanMaxDate && endDateIsAfterStartDate;
      setValidation({
        ...validation,
        endDate: endDateValid
      });
    }
  }, [values.endDate]);

  useEffect(() => {
    if(didMount){
      setIsWeeklyOrDaily(values.frequency==Frequency.Weekly || values.frequency==Frequency.Daily);
      setValidation({
        ...validation,
        frequency: true
      });
    }
  }, [values.frequency]);

  useEffect(() => {
    if(didMount){
      //const startHeightValid = startHeightRegex.test(values.startHeight);
      const startHeightValid = true;
      setValidation({
        ...validation,
        startHeight: startHeightValid
      });
    }
  }, [values.startHeight]);

  useEffect(() => {
    if(didMount){
      //const endHeightValid = endHeightRegex.test(values.endHeight);
      const endHeightValid = true;
      setValidation({
        ...validation,
        endHeight: endHeightValid
      });
    }
  }, [values.endHeight]);

  useEffect(() => {
    if(didMount){
      const energyEquivalentValid = energyEquivalentRegex.test(values.energyEquivalent?.toString());
      setValidation({
        ...validation,
          energyEquivalent: energyEquivalentValid
      });
    }
  }, [values.energyEquivalent]);

  useEffect(() => {
    if(didMount){
      const hrvWaterValueValid = hrvWaterValueRegex.test(values.hrvWaterValue?.toString());
      setValidation({
        ...validation,
          hrvWaterValue: hrvWaterValueValid
      });
    }
  }, [values.hrvWaterValue]);

  useEffect(() => {
    if(didMount){
      const lrvWaterValueValid = lrvWaterValueRegex.test(values.lrvWaterValue?.toString());
      setValidation({
        ...validation,
          lrvWaterValue: lrvWaterValueValid
      });
    }
  }, [values.lrvWaterValue]);
  
  useEffect(() => {
    if(didMount){
      setIfValidated();
    }
  }, [validation]);

  // Check if this solves the init problem. 
  // useEffect(() => {
  //   if(didMount){
  //     setIfValidated();
  //   }
  // }, [boundaries]);

  const setIfValidated = () =>{
    if(values.frequency != undefined && validation.frequency && 
      values.startDate != '' && validation.startDate && 
      values.endDate != '' && validation.endDate &&
      values.startHeight >= 0 && validation.startHeight && 
      (
        values.endHeight >= 0 && validation.endHeight || 
        (values.energyEquivalent != undefined && validation.energyEquivalent) && 
        (values.hrvWaterValue != undefined && validation.hrvWaterValue) && 
        (values.lrvWaterValue != undefined && validation.lrvWaterValue)
      )){
      setValue(values);
      setValid(true);
    }else{
      setValid(false);
    }
  }

  // const waterValueSetupIsComplete = () => {
  //   return values.energyEquivalent != null && 
  //   values.hrvWaterValue != null && 
  //   values.lrvWaterValue != null
  // }
  
  function valuetext(value) {
    return `${value}L`;
  }

    const minDate = dayjs(boundaries?.minDate);
    const maxDate = dayjs(boundaries?.maxDate);

    return (
      boundaries &&
      <React.Fragment>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
        <Card sx={{ margin: 2 }}>
            <CardContent>
                <Stack spacing={2}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Enter details for the {FrequencyPrint(values.frequency)} calculation
                  </Typography>
                  <Stack direction="row" spacing={3} >
                  <FormControl sx={{ minWidth: 165 }}>
                    <React.Fragment>
                      <InputLabel id="x-helper-label">{helperTexts.frequency}</InputLabel>
                      <Select
                          labelId="frequency-select-helper-label"
                          id="frequency-input"
                          name="frequency"
                          value={values.frequency}
                          label={helperTexts.frequency}
                          onChange={handleInputChange}
                        >
                          <MenuItem value={Frequency.Hourly}>Hourly</MenuItem>
                          <MenuItem value={Frequency.Daily}>Daily</MenuItem>
                          <MenuItem value={Frequency.Weekly}>Weekly</MenuItem>
                      </Select>
                      </React.Fragment>
                    </FormControl>
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
                    {/* <DateTimeRangePicker value={range} onChange={(newValue) => setRange(newValue)} /> */}
    
                    {isWeeklyOrDaily &&
                    <React.Fragment>
                      <DatePicker
                      name="startDate"
                      label="Start date"
                      minDate={minDate}
                      maxDate={maxDate}
                      value={dayjs(values.startDate)}
                      onChange={(newValue) => handleStartDateChange(newValue)}
                      />
                      <DatePicker
                      name="endDate"
                      label="End date"
                      minDate={minDate}
                      maxDate={maxDate}
                      value={dayjs(values.endDate)}
                      onChange={(newValue) => handleEndDateChange(newValue)}
                    />
                    </React.Fragment>
                    }
                    {!isWeeklyOrDaily &&
                    <React.Fragment>
                      <DateTimePicker
                      name="startDate"
                      label="Start date"
                      minDate={minDate}
                      maxDate={maxDate}
                      value={dayjs(values.startDate)}
                      onChange={(newValue) => handleStartDateChange(newValue)}
                      />
                      <DateTimePicker
                      name="endDate"
                      label="End date"
                      minDate={minDate}
                      maxDate={maxDate}
                      value={dayjs(values.endDate)}
                      onChange={(newValue) => handleEndDateChange(newValue)}
                      />
                    </React.Fragment>
                    }

                    </LocalizationProvider>
                    
                  </Stack>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Start height:
                  </Typography>
                  <Stack>
                  <Slider
                    name="startHeight"
                    aria-label="Start height"
                    defaultValue={defaultValues.startHeight}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={boundaries.step}
                    min={boundaries.lrv}
                    max={boundaries.hrv}
                    value={values.startHeight}
                    onChange={handleInputChange}
                    orientation='horizontal'
                  />
                  </Stack>
                  {endHeightOption &&
                  <React.Fragment>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      End height:
                    </Typography>
                    <Slider
                      name="endHeight"
                      aria-label="End height"
                      defaultValue={defaultValues.endHeight}
                      getAriaValueText={valuetext}
                      valueLabelDisplay="auto"
                      step={boundaries.step}
                      min={boundaries.lrv}
                      max={boundaries.hrv}
                      value={values.endHeight}
                      onChange={handleInputChange}
                      orientation='horizontal'
                    />
                  </React.Fragment>
                  }
                {!endHeightOption &&
                  <Grid name="waterValueSetup" container >
                  <Grid xs={2}>
                    <TextField sx={{ mr: 1 }} id="step-input" name="energyEquivalent" label="Energy equivalent" variant="outlined" value={values.energyEquivalent} onChange={handleInputChange} error={!validation.energyEquivalent} helperText={helperTexts.energyEquivalent}/>
                  </Grid>
                  <Grid xs={2}>
                    <TextField sx={{ mr: 1 }} id="step-input" name="hrvWaterValue" label="Hrv water value" variant="outlined" value={values.hrvWaterValue} onChange={handleInputChange} error={!validation.hrvWaterValue} helperText={helperTexts.hrvWaterValue}/>
                  </Grid>
                  <Grid xs={2}>
                    <TextField sx={{ mr: 1 }} id="step-input" name="lrvWaterValue" label="Lrv water value" variant="outlined" value={values.lrvWaterValue} onChange={handleInputChange} error={!validation.lrvWaterValue} helperText={helperTexts.lrvWaterValue}/>
                  </Grid>
                </Grid>
                }
                <Stack direction="row" spacing={1} alignItems="center" >
                    <Switch
                      checked={!endHeightOption}
                      onChange={handleToggleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>End water value option:</Typography>
                  </Stack>
                </Stack>
            </CardContent>
        </Card>
      {/* </LocalizationProvider> */}
      </React.Fragment>
    );
}