import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {SetupType} from '../common/types';
import Stack from '@mui/material/Stack';
// import { UserProfileContext } from '../App';
import PropTypes from 'prop-types';


EditSpec.propTypes = {
  spec: PropTypes.object,
  setSpec: PropTypes.func,
  setValid: PropTypes.func
};
//{spec, setSpec, setValid}

export default function EditSpec({spec, setSpec, setValid}) {

  // const spec = {
  //   setupType : SetupType.reservoir,
  //   description: "",
  //   x: "A",
  //   y: "B",
  //   start: "2"
  // }

  const defaultValidation = {
    setupType: true,
    description: true,
    x: true,
    y: true,
    start: true,
    end: true
  };

  const helperTexts = {
    setupType: "Type of setup",
    description: "Description",
    worksheetLabel: "Worksheet",
    xLabel: "Column for date",
    xReservoirLabel: "Column for MASL",
    xGeneratorLabel: "???G Label",
    xTurbineLabel: "???T Label",
    xDate: "dd.MM.yyyy HH:mm:SS",
    xReservoir: "Meter above sea level",
    xTurbine: "??? turbine",
    xGenerator: "??? generator",
    yReservoirLabel: "Column for volume",
    yInflowLabel: "Column for inflow",
    yPriceLabel: "Column for price",
    yGeneratorLabel: "Column for gen",
    yTurbineLabel: "Column for turb",
    yReservoir: "M^3*10^3",
    yPrice: "1000*10^3NOK/TW",
    yInflow: "M3/sek",
    yTurbine: "y-turb-benevning",
    yGenerator: "y-gen-benevning",
    start: "Start row",
    end: "End row",
    worksheet: "Worksheet for setup"
  };

  //const {userProfile} = useContext(UserProfileContext);

  const [values, setValues] = useState(spec); 
  const [validation, setValidation] = useState(defaultValidation);

  const [didMount, setDidMount] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, []);

  // useEffect(() => {
  //   if(didMount){
  //     if(userProfile != null && userProfile.defaultPlant != null){
  //       setValues(userProfile.defaultPlant);
  //     }
  //   }
  // }, [userProfile]);

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValues({
      ...values,
      [name]: value,
      });
  };
 
  const descriptionRegex = new RegExp("^(?!\\s)(?!.*\\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{0,30}$");
  const startRegex = new RegExp("^[]?([0-9]{1,4}?)$");
  const endRegex = new RegExp("^[]?([0-9]{1,4}?)$");
  const worksheetRegex = new RegExp("^[]?([0-9]{1,2}?)$");
  
  useEffect(() => {
    if(didMount){
      const valid = worksheetRegex.test(values.worksheet);
      setValidation({
        ...validation,
        worksheet: valid
      });
    }
  }, [values.worksheet]);

  useEffect(() => {
    if(didMount){
      //const valid = startRegex.test(values.set);
      setValidation({
        ...validation,
          setupType: true
      });
    }
  }, [values.setupType]);

  useEffect(() => {
    if(didMount){
      const valid = values.description == undefined || values.description.length == 0 || 
                  descriptionRegex.test(values.description);
      setValidation({
        ...validation,
          description: valid
      });
    }
  }, [values.description]);

  useEffect(() => {
    if(didMount){
      //const valid = xRegex.test(values.x);
      setValidation({
        ...validation,
          x: true
      });
    }
  }, [values.x]);

  useEffect(() => {
    if(didMount){
      //const valid = yRegex.test(values.y);
      setValidation({
        ...validation,
          y: true
      });
    }
  }, [values.y]);

  useEffect(() => {
    if(didMount){
      const valid = startRegex.test(values.start);
      setValidation({
        ...validation,
          start: valid
      });
    }
  }, [values.start]);

  const startIsSmallerThanEnd = () => {
    if(values.end && values.start && values.start < values.end){
      return true;
    }
    return false;
  }

  // const endHasNoValue = () => {
  //   return values.end == undefined || values.end.length == 0;
  // }

  useEffect(() => {
    if(didMount){
      if(values.end == ''){
        values.end = null;
      }
      let valid = values.end == undefined || values.end.length == 0 || endRegex.test(values.end);
      if(values.end && valid)
        valid = startIsSmallerThanEnd();
      setValidation({
        ...validation,
        end: valid
      });
    }
  }, [values.end]);


  useEffect(() => {
    if(didMount){
      setSpecIfValidated();
    }
  }, [validation]);

  const setSpecIfValidated = () =>{
    if(validation.x && 
      validation.y && 
      validation.start && 
       validation.end && 
      validation.setupType){
        //replaceEmptyWithNull(values);
      setSpec(values);
      setValid(values,true);
    }else{
        setValid(values,false);
    }
  }

  // const replaceEmptyWithNull = (values) => {
  //   if(values.end == undefined || values.end == ''){
  //      values.end = null;
  //   }
  // }


  const getXLabel = () => {
    if (values.setupType === SetupType.reservoir) 
         return helperTexts.xReservoirLabel;
    if (values.setupType === SetupType.generatorpower) 
         return helperTexts.xGeneratorLabel;
    if (values.setupType === SetupType.turbinepower) 
         return helperTexts.xTurbineLabel;
    return helperTexts.xLabel;
  }
  
  const getXHelperText = () => {
    if (values.setupType === SetupType.reservoir) 
         return helperTexts.xReservoir;
    if (values.setupType === SetupType.generatorpower) 
         return helperTexts.xGenerator;
    if (values.setupType === SetupType.turbinepower) 
         return helperTexts.xTurbine;
    return helperTexts.xDate;
  }

  const getYLabel = () => {
    if (values.setupType === SetupType.reservoir) 
      return helperTexts.yReservoirLabel;
    if (values.setupType === SetupType.inflow) 
      return helperTexts.yInflowLabel;
    if (values.setupType === SetupType.price) 
      return helperTexts.yPriceLabel;
    if (values.setupType === SetupType.generatorpower) 
      return helperTexts.yGeneratorLabel;
    if (values.setupType === SetupType.turbinepower) 
      return helperTexts.yTurbineLabel;
  }

  const getYHelperText = () => {
    if (values.setupType === SetupType.reservoir) 
      return helperTexts.yReservoir;
    if (values.setupType === SetupType.inflow) 
      return helperTexts.yInflow;
    if (values.setupType === SetupType.price) 
      return helperTexts.yPrice;
    if (values.setupType === SetupType.generatorpower) 
      return helperTexts.yGenerator;
    if (values.setupType === SetupType.turbinepower) 
      return helperTexts.yTurbine;
  }

  const getDescription = () =>{
    if(values.description == undefined)
      return '';
    return values.description;
  }

  return (
    <React.Fragment>
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} color="text.secondary" gutterBottom>
          Enter details for this setup
        </Typography>
        <Stack spacing={1} >
        <Stack direction="row" spacing={1} >
        <FormControl sx={{ minWidth: 165 }}>
        <InputLabel id="ws-helper-label">{helperTexts.worksheetLabel}</InputLabel>
          <React.Fragment>
            {/* <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
            <TextField sx={{ width: 155+165+165 }} id="worksheet-input" name="worksheet" label={helperTexts.workshett} variant="outlined" value={values.worksheet} onChange={handleInputChange} error={!validation.worksheet} />
          </Typography> */}
             <Select
                labelId="ws-select-helper-label"
                id="ws-input"
                name="worksheet"
                value={values.worksheet}
                label={helperTexts.worksheetLabel}
                onChange={handleInputChange}
              >
                <MenuItem value="0">1</MenuItem>
                <MenuItem value="1">2</MenuItem>
                <MenuItem value="2">3</MenuItem>
                <MenuItem value="3">4</MenuItem>
                <MenuItem value="4">5</MenuItem>
                <MenuItem value="5">6</MenuItem>
                <MenuItem value="6">7</MenuItem>
                <MenuItem value="7">8</MenuItem>
                <MenuItem value="8">9</MenuItem>
                <MenuItem value="9">10</MenuItem>
                <MenuItem value="10">11</MenuItem>
                <MenuItem value="11">12</MenuItem>
                <MenuItem value="12">13</MenuItem>
                <MenuItem value="13">14</MenuItem>
                <MenuItem value="14">15</MenuItem>
                <MenuItem value="15">16</MenuItem>
                <MenuItem value="16">17</MenuItem>
                <MenuItem value="17">18</MenuItem>
                <MenuItem value="18">19</MenuItem>
                <MenuItem value="19">20</MenuItem> 
                <MenuItem value="20">21</MenuItem>
              </Select>
              {/* <FormHelperText>{helperTexts.worksheet}</FormHelperText> */}
            </React.Fragment>
         
        </FormControl>
        <FormControl sx={{ minWidth: 165 }}>
        <InputLabel id="setuptype-helper-label">{helperTexts.setupType}</InputLabel>
          { spec.setupType &&
          <React.Fragment>
            <Select
                labelId="setupType-select-helper-label"
                id="setupType-input"
                name="setupType"
                value={values.setupType}
                label={helperTexts.setupType}
                onChange={handleInputChange}
              >
                <MenuItem value={SetupType.availability}>Unavailability</MenuItem>
                <MenuItem value={SetupType.reservoir}>Reservoir</MenuItem>
                <MenuItem value={SetupType.inflow}>Inflow</MenuItem>
                <MenuItem value={SetupType.price}>Price</MenuItem>
                <MenuItem value={SetupType.turbinepower}>Turbine efficiency</MenuItem>
                <MenuItem value={SetupType.generatorpower}>Generator efficiency</MenuItem>
              </Select>
              {/* <FormHelperText>{helperTexts.setupType}</FormHelperText> */}
            </React.Fragment>
          }
        </FormControl>
       
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
            <TextField sx={{ width: 140 }} id="start-input" name="start" label={helperTexts.start} variant="outlined" value={values.start} onChange={handleInputChange} error={!validation.start} />
        </Typography>
        
        </Stack>
        <Stack direction="row" spacing={1} >
        <FormControl sx={{ minWidth: 165 }}>
        <InputLabel id="x-helper-label">{getXLabel()}</InputLabel>
          { spec.x &&
          <React.Fragment>
            <Select
                labelId="x-select-helper-label"
                id="x-input"
                name="x"
                value={values.x}
                label={getXLabel()}
                onChange={handleInputChange}
              >
                <MenuItem value="A">A</MenuItem>
                <MenuItem value="B">B</MenuItem>
                <MenuItem value="C">C</MenuItem>
                <MenuItem value="D">D</MenuItem>
                <MenuItem value="E">E</MenuItem>
                <MenuItem value="F">F</MenuItem>
                <MenuItem value="G">G</MenuItem>
                <MenuItem value="H">H</MenuItem>
                <MenuItem value="I">I</MenuItem>
                <MenuItem value="J">J</MenuItem>
                <MenuItem value="K">K</MenuItem>
                <MenuItem value="L">L</MenuItem>
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="N">N</MenuItem>
                <MenuItem value="O">O</MenuItem>
                <MenuItem value="P">P</MenuItem>
                <MenuItem value="Q">Q</MenuItem>
                <MenuItem value="R">R</MenuItem>
                <MenuItem value="S">S</MenuItem>
                <MenuItem value="T">T</MenuItem>
                <MenuItem value="U">U</MenuItem>
                <MenuItem value="V">V</MenuItem>
                <MenuItem value="W">W</MenuItem>
                <MenuItem value="X">X</MenuItem>
                <MenuItem value="Y">Y</MenuItem>
                <MenuItem value="Z">Z</MenuItem>
              </Select>
              <FormHelperText>{getXHelperText()}</FormHelperText>
            </React.Fragment>
          }
        </FormControl>
        <FormControl sx={{ minWidth: 165 }}>
        <InputLabel id="x-helper-label">{getYLabel()}</InputLabel>
          { spec.y &&
          <React.Fragment>
            <Select
                labelId="y-select-helper-label"
                id="y-input"
                name="y"
                value={values.y}
                label={getYLabel()}
                onChange={handleInputChange}
              >
                <MenuItem value="A">A</MenuItem>
                <MenuItem value="B">B</MenuItem>
                <MenuItem value="C">C</MenuItem>
                <MenuItem value="D">D</MenuItem>
                <MenuItem value="E">E</MenuItem>
                <MenuItem value="F">F</MenuItem>
                <MenuItem value="G">G</MenuItem>
                <MenuItem value="H">H</MenuItem>
                <MenuItem value="I">I</MenuItem>
                <MenuItem value="J">J</MenuItem>
                <MenuItem value="K">K</MenuItem>
                <MenuItem value="L">L</MenuItem>
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="N">N</MenuItem>
                <MenuItem value="O">O</MenuItem>
                <MenuItem value="P">P</MenuItem>
                <MenuItem value="Q">Q</MenuItem>
                <MenuItem value="R">R</MenuItem>
                <MenuItem value="S">S</MenuItem>
                <MenuItem value="T">T</MenuItem>
                <MenuItem value="U">U</MenuItem>
                <MenuItem value="V">V</MenuItem>
                <MenuItem value="W">W</MenuItem>
                <MenuItem value="X">X</MenuItem>
                <MenuItem value="Y">Y</MenuItem>
                <MenuItem value="Z">Z</MenuItem>
              </Select>
              <FormHelperText>{getYHelperText()}</FormHelperText>
            </React.Fragment>
          }
        </FormControl>
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
            <TextField number sx={{ width: 140 }} id="end-input" name="end" label={helperTexts.end} variant="outlined" value={values.end} onChange={handleInputChange} error={!validation.end} />
        </Typography>
        </Stack>
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
            <TextField sx={{ width: 155+165+165 }} id="description-input" name="description" label={helperTexts.description} variant="outlined" value={getDescription()} onChange={handleInputChange} error={!validation.description} />
        </Typography>
        </Stack>
      </CardContent>
    </Card>
    </React.Fragment>
  );
}

