import React, {useState, useContext, useEffect}  from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AuthContext, UserProfileContext } from '../App';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import EditCompanyBackdrop from './EditCompanyBackdrop';
import DeleteCompany from './DeleteCompany';
import { v4 as uuidv4 } from 'uuid';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import {Company} from '../common/types'
import { ErrorMessage } from '../common/Constant';

//export const CompanyContext = React.createContext(defaultCompany);

export default function CompaniesTable () {

  const authContext = useContext(AuthContext);
  const {setUserProfile} = useContext(UserProfileContext);
  
  const [loading, setLoading] = useState(true);

  const [confirmation, setConfirmation] = useState({open:false,message:""});
  const [rejection, setRejection] = useState({open:false,message:""});

  const [companies, setCompanies] = useState<Company[]>([]);
  const [editCompany, setEditCompany] = useState<Company>();

  const [didMount, setDidMount] = useState(false);
   

    // Setting didMount to true upon mounting
  useEffect(() => { 
    setDidMount(true) 
    populateCompaniesData();
  }, [])

  // useEffect(() => { 
  //   if(didMount){
  //     populateCompaniesData();
  //   }
  // }, [userProfile])

  useEffect(() => { 
    if(didMount){
      setLoading(false);
    }
  }, [companies])


  const getEmptyCompany = () : Company => {
    return {
      id: uuidv4(), 
      name: '', 
      email: '', 
      vatNumber: '', 
      plants: [],
      users: [ {id: uuidv4(), email: '', authId:'', userType:'admin', isNew: true} ], 
      isNew: true
    }
  };


  const populateCompaniesData = () => {
    fetch('api/companies', 
    {
      headers: { Authorization: `Bearer ${authContext.token}` }
    })
    .then(response => {
      if(response.ok) {
        response.json().then(companies => {
          setCompanies(companies);
        });
      }else if(response.status == 400){
        response.json().then(error => {
          setRejection({open:true, message: ErrorMessage(error)});
          setCompanies([]);
        });
      }else{
        setRejection({open:true, message:"Server error"});
        setCompanies([]);
      }
      
    }).catch((err) => {
      console.log(err.message);
      setRejection({open:true, message:"Internal error"});
      setCompanies([]);
    });
  }

  // const handleAddCompany = (company) => {
  //   populateCompaniesData();
  //   setConfirmation({open:true,message:"Company was added: " + company.name});
  // }
  
  const reloadUserProfile = () => {
    setUserProfile(null);
  };

  const handleEditCompany = (company: Company) => {
    if(company != null){
      reloadUserProfile();
      populateCompaniesData();
      if(editCompany != null && editCompany.isNew){
        setConfirmation({open:true,message:"company was added: " + company.name});
      }else{
        setConfirmation({open:true,message:"company was saved: " + company.name});
      }
    }
    setEditCompany(null);
  }

  const handleDeleteCompany = (company: Company) => {
    reloadUserProfile();
    populateCompaniesData();
    setConfirmation({open:true,message:"Company was deleted: " + company.name});
  }
  

   const handleConfirmationClose = () => {
    setConfirmation({open:false,message:""});
  }

  const handleRejectionClose = () => {
    setRejection({open:false,message:""});
  }
  

  return (
    loading ? 
    <Box sx={{ display: 'flex' }}>
    <CircularProgress />
    </Box>  :
      <React.Fragment>
      <Snackbar
        open={confirmation.open}
        autoHideDuration={3000}
        onClose={handleConfirmationClose}
        message={confirmation.message}
      />
      <Snackbar
        open={rejection.open}
        autoHideDuration={3000}
        onClose={handleRejectionClose}
        message= {rejection.message}
      />
    <Card sx={{ margin: 2 }}>
      <CardContent>
        {editCompany != null && 
        <EditCompanyBackdrop defaultCompany={editCompany} editCompanyCallback={handleEditCompany} />
        }
        <Button onClick={() => setEditCompany(getEmptyCompany())}>Add company</Button>
      {
      companies.length == 0 ?
        <div/>
      :
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }} size="small" aria-label="companies">
            <TableHead>
              <TableRow>
                <TableCell>Company name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">VAT number</TableCell>
                <TableCell>Functions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((company) => (
                <TableRow key={company.id}>
                  <TableCell component="th" scope="row">{company.name}</TableCell>
                  <TableCell align="right">{company.email}</TableCell>
                  <TableCell align="right">{company.vatNumber}</TableCell>
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => setEditCompany(company)} >
                      <EditIcon/>
                    </IconButton>
                    <DeleteCompany defaultCompany={company} deleteCompanyCallback={handleDeleteCompany}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        }
      </CardContent>
      </Card>
    </React.Fragment>
  );
}